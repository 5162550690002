import classNames from "classnames";
import { useEffect, useState } from "react";

import { PROJECT_STYLES } from "../utils";
import ImageButton from "../../shared/ImageButton";

const ProjectStyle = ({
  jobRef,
  user,
  setDisabled,
  save,
  isCurrent,
  className,
  setReminderText,
}) => {
  const [selectedStyles, setSelectedStyles] = useState(
    user.projectStyles || []
  );

  const isSelected = (styleId) => selectedStyles.find((s) => s.id === styleId);

  const handleClick = (style) => {
    let newValue = [...selectedStyles];
    if (isSelected(style.id))
      newValue = newValue.filter((s) => s.id !== style.id);
    else newValue.push({ id: style.id, title: style.title });

    setSelectedStyles(newValue);
    save({ projectStyles: newValue }, true);
  };

  useEffect(() => {
    if (!isCurrent) return;
    setDisabled(selectedStyles.length === 0);
    if (selectedStyles.length === 0) {
      setReminderText("Select at least one");
    } else {
      setReminderText("");
    }
  }, [setReminderText, selectedStyles, setDisabled, isCurrent]);

  return (
    <div className={classNames(className)} ref={jobRef}>
      <div className="b2 mb-8">Which types of projects interest you?</div>
      <div className="grid md:flex md:flex-wrap justify-items-center gap-3 grid-cols-2 mb-[1vh]">
        {PROJECT_STYLES.map((style) => (
          <ImageButton
            key={style.id}
            text={style.title}
            selected={isSelected(style.id)}
            logo={
              <img
                className="h-full w-auto md:object-contain"
                src={require(`images/imagery/${style.logo}`)}
                alt="logo"
              />
            }
            handleClick={() => handleClick(style)}
            greedyText
          />
        ))}
      </div>
    </div>
  );
};

export default ProjectStyle;
