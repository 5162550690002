import React, { useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import Footer from "components/Footer";
import Navigation from "components/Navigation";
import Logo from "components/Logo/Logo";
import { API, Auth } from "aws-amplify";
import { gtp } from "graphql/mutations";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Password from "components/FormInputs/Password";
import ConfirmPassword from "components/FormInputs/ConfirmPassword";
import PrimaryBtn from "components/buttons/Primary";
import Error from "components/FormComponentsNew/Error";

let validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Please enter your password")
    .min(8, "Minimum length of a password is 8 characters")
    .max(256, "Maximum length of a password is 256 characters.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Please ensure your password contains at least one, upper and lowercase characters, one number, and one special characters, and is of minimum length 8"
    ),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const formOptions = {
  resolver: yupResolver(validationSchema),
  defaultValues: {
    password: "",
    confirmPassword: "",
  },
  mode: "all",
};

// !Code can be removed if query param are being extracted correctly
// function extractValues(str) {
//   const idunMatch = str.match(/\?idun=([^&]+)/);
//   const tpMatch = str.match(/&tp=([^]+)/);

//   return {
//     idun: idunMatch ? idunMatch[1] : null,
//     tp: tpMatch ? tpMatch[1] : null,
//   };
// }

function ExternalLogin() {
  const params = new URLSearchParams(useLocation().search);

  useEffect(() => {
    document.title = "Torc - Verify your email";
  }, []);

  useEffect(() => {
    if (params.has("email")) {
      let emailParam = params.get("email");
      if (!emailParam.includes("@")) {
        emailParam = atob(emailParam);
      }

      (async () => {
        await API.graphql({
          query: gtp,
          variables: { input: { email: emailParam } },
          authMode: "API_KEY",
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!params.has("email")) {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }

  return (
    <>
      <div className="object-small-hidden">
        <Navigation showButtons={false} />
      </div>

      <div className="container-large">
        <Logo />
        <div className="large-section container-left">
          <h1 className="mb-8">Welcome To Torc</h1>
          <div className="b1 mb-8">
            An email has been sent to you for verification. Please click on the
            link in the email to continue.
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

function ExternalSetPassword() {
  const methods = useForm(formOptions);
  const { handleSubmit, setError, formState } = methods;
  const { errors, isSubmitting } = formState;

  const [isLoading, setIsLoading] = useState(false);

  const params = new URLSearchParams(useLocation().search);

  const idun = params.get("idun");
  const tp = params.get("tp");

  const onSubmit = async ({ password }) => {
    setIsLoading(true);

    try {
      const user = await Auth.signIn(idun, tp);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        // Execution of this code should trigger Amplify auth event
        // which will then be picked up by Auth Provider and redirect user
        // automatically
        await Auth.completeNewPassword(user, password);
      } else {
        setError("apiError", {
          message:
            "An error occurred trying to set your password. Please contact administrator to resolve it",
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error", error);
    }
  };

  useEffect(() => {
    document.title = "Torc - Setup your account";
  }, []);

  if (!idun || !tp) {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }

  return (
    <>
      <div className="object-small-hidden">
        <Navigation showButtons={false} />
      </div>

      <div className="container-large">
        <Logo />
        <div className="large-section container-left">
          <h1 className="mb-8">Set Your Password</h1>
          <div className="b1 mb-8">
            Thank you for verifying your account. Please provide a password for
            your account below.
          </div>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="sm:w-2/3 ml-auto grid gap-8 sm:mt-16"
            >
              <Password />
              <ConfirmPassword />

              <div className="flex flex-row items-center justify-end gap-8 py-4 w-full">
                <PrimaryBtn
                  loading={isSubmitting}
                  label={!isLoading ? "Submit" : "Submitting..."}
                  disabled={isSubmitting}
                />
              </div>
              {errors.apiError && <Error message={errors.apiError?.message} />}
            </form>
          </FormProvider>
        </div>
      </div>
      <Footer />
    </>
  );
}

export { ExternalLogin, ExternalSetPassword };
