import { useState } from "react";
import { ReactComponent as List } from "images/list.svg";
import { ReactComponent as RightLeft } from "images/right-left.svg";
import { ReactComponent as LeftArrowIcon } from "images/left-arrow-icon.svg";
import { ReactComponent as RightArrowIcon } from "images/right-arrow-icon.svg";
import { ReactComponent as CalendarIcon } from "images/calendar.svg";
import { ReactComponent as CrossIcon } from "images/cross.svg";
import classNames from "classnames";
import { CANDIDATES_VIEW_MODES } from "lookup";
import Modal from "components/Modal";
import Calendar from "components/Calendar";

export default function SelectCandidatesViewPanel({
  jobCalendarEvents,
  reFreshCalendarEvents,
  mode,
  onModeChange,
  onScrollLeft,
  onScrollRight,
  title,
}) {
  const [showModal, setShowModal] = useState(false);

  const isLVMode = () => {
    return mode === CANDIDATES_VIEW_MODES.LISTVIEW;
  };

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div
        className={classNames(
          "flex justify-between items-center font-bold text-gray-400",
          {
            "mb-7": isLVMode(),
            "px-4 w-[420px]": !isLVMode(),
          }
        )}
      >
        {title}
        <div className="flex items-center">
          <List
            className={classNames(
              { "opacity-25": !isLVMode() },
              "cursor-pointer",
              "hover:cursor-pointer",
              "hover:opacity-100"
            )}
            onClick={() => onModeChange(CANDIDATES_VIEW_MODES.LISTVIEW)}
          />
          <RightLeft
            className={classNames(
              { "opacity-25": isLVMode() },
              "cursor-pointer",
              "hover:cursor-pointer",
              "hover:opacity-100"
            )}
            onClick={() => onModeChange(CANDIDATES_VIEW_MODES.GRIDVIEW)}
          />
          {!!jobCalendarEvents && (
            <CalendarIcon
              className="ml-1 opacity-25 cursor-pointer hover:opacity-100"
              title="View Calendar"
              onClick={() => setShowModal(true)}
            />
          )}
        </div>
      </div>
      {!isLVMode() && (
        <div className="w-full max-w-5xl flex justify-end">
          <LeftArrowIcon
            className="mr-4 hover:scale-150 hover:cursor-pointer"
            onClick={onScrollLeft}
          />
          <RightArrowIcon
            className="hover:scale-150 hover:cursor-pointer"
            onClick={onScrollRight}
          />
        </div>
      )}
      {showModal && (
        <Modal onClose={onClose}>
          <div
            className={classNames(
              "w-[65vw] h-[90vh] bg-white rounded-none no-scrollbar overflow-hidden relative rounded_modal px-4 py-2"
            )}
          >
            <CrossIcon
              onClick={onClose}
              className="absolute top-2 right-3 w-[25px] h-[25px] opacity-50 cursor-pointer hover:opacity-100 z-50"
            />
            <Calendar
              calendarEvents={jobCalendarEvents}
              reFreshCalendarEvents={reFreshCalendarEvents}
              noResultsMessage={"Currently this job has no meetings booked"}
            />
          </div>
        </Modal>
      )}
    </>
  );
}
