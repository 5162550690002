import { useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";

import ImageButton from "../../shared/ImageButton";
import { createApplication } from "graphql/mutations";
import classNames from "classnames";

const Role = ({
  jobRoles,
  setJobRoles,
  roleRef,
  setDisabled,
  isCurrent,
  setAutoSaved,
  className,
  setReminderText,
}) => {
  useEffect(() => {
    if (!isCurrent) return;
    const shouldDisable = !jobRoles?.some(({ isApplied }) => isApplied);
    setDisabled(shouldDisable);
    if (shouldDisable) {
      setReminderText("Select at least one");
    } else {
      setReminderText("");
    }
  }, [jobRoles, setDisabled, isCurrent, setReminderText]);

  const toggleIsActiveField = (jobTypeId) => {
    setJobRoles(
      jobRoles.map((jr) => {
        if (jobTypeId === jr.id) {
          jr.isApplied = !jr.isApplied;
        }
        return jr;
      })
    );
  };

  async function applyToJobRole(jobTypeId) {
    try {
      toggleIsActiveField(jobTypeId);
      await API.graphql(
        graphqlOperation(createApplication, { input: { jobTypeId } })
      );
    } catch (err) {
      toggleIsActiveField(jobTypeId);
      console.log("Error when submitting application to db");
      console.log(err);
    }
  }

  return (
    <div
      ref={roleRef}
      className={classNames("flex flex-col gap-6 md:gap-10", className)}
    >
      <div className="flex items-baseline gap-4">
        <div className="b2">Select the roles that best describe you.</div>
      </div>
      <div className="grid md:flex md:flex-wrap justify-items-center gap-3 grid-cols-2">
        {jobRoles
          .sort((a, b) => a.sort - b.sort)
          .filter((j) => j.logo)
          .map((jobRole, index) => (
            <ImageButton
              key={index}
              selected={jobRole.isApplied}
              logo={
                <img
                  className="h-full w-auto md:object-contain"
                  src={require(`images/imagery/${jobRole.logo}`)}
                  alt="logo"
                />
              }
              handleClick={async () => {
                await applyToJobRole(jobRole.id);
                setAutoSaved(true);
              }}
              text={jobRole.displayName}
            />
          ))}
      </div>
    </div>
  );
};

export default Role;
