import { useMemo } from "react";
import { useAuth } from "GlobalAuthContext";
import Header from "components/Header";
import { useParams, Redirect } from "react-router-dom";

const iframeClasses =
  "fixed top-16 bottom-0 right-0 w-full border-0 m-0 p-0 overflow-hidden h-[calc(100%-4rem)]";

export default function ExternalPage() {
  const auth = useAuth();

  const { type } = useParams();

  const externalConfig = useMemo(() => {
    return {
      "torc-store": {
        title: "Torc Store",
        src: `https://torc.my.salesforce-sites.com/torcshop?id=${auth.user.id}&logo=0`,
      },
      "full-report": {
        title: "Full Report",
        src: `https://lookerstudio.google.com/embed/reporting/dd8c4836-f8a0-4169-a794-0791d1d68add/page/NODdD?params=%7B%22df110%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth.user.id}%22%7D`,
      },
      leaderboard: {
        title: "Leaderboard",
        src: "https://lookerstudio.google.com/embed/u/0/reporting/13980665-d982-473c-97ea-849034676d70/page/NODdD",
      },
    };
  }, [auth.user.id]);

  if (!externalConfig[type]) {
    return <Redirect to="/NotFound" />;
  }

  return (
    <>
      <Header />
      <iframe
        src={externalConfig[type].src}
        title={externalConfig[type].title}
        className={iframeClasses}
      />
    </>
  );
}
