import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import SocialLoginButton from "components/buttons/SocialLoginButton";
import { SIGNUP_TYPES, USER_TYPES } from "lookup";
import PrimaryBtn from "components/buttons/Primary";
import EmailForm from "pages/Auth/Signup/Form/EmailForm";

const SignUpOptions = ({ isEnterprise, userName, setUserName }) => {
  const [signUpType, setSignUpType] = useState();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/more-info-needed") setSignUpType(SIGNUP_TYPES.GOOGLE);
  }, [pathname]);

  return (
    <>
      <div className="b1 mb-14">
        {isEnterprise
          ? "Simplify and accelerate your hiring journey with us."
          : "Find your dream job and build your freelance career."}
      </div>
      <div className="w-full">
        <div className="flex flex-wrap gap-7 md:gap-0 lg:gap-7 flex-col lg:flex-row">
          <div className="flex flex-wrap gap-7 flex-col md:flex-row lg:flex-[2]">
            <div className="flex-1">
              <SocialLoginButton
                type="github"
                userType={
                  isEnterprise ? USER_TYPES.CUSTOMER : USER_TYPES.FREELANCER
                }
                isSignup
              />
            </div>
            <div className="flex-1">
              <SocialLoginButton
                type="google"
                userType={
                  isEnterprise ? USER_TYPES.CUSTOMER : USER_TYPES.FREELANCER
                }
                isSignup
              />
            </div>
          </div>
          <div className="h-full lg:flex-1">
            <PrimaryBtn
              label="Sign up with Email"
              onClick={() => setSignUpType(SIGNUP_TYPES.EMAIL)}
              fullWidth
              className={"!h-full"}
              selected={signUpType === SIGNUP_TYPES.EMAIL}
            />
          </div>
        </div>

        {signUpType === SIGNUP_TYPES.EMAIL && (
          <EmailForm
            userName={userName}
            setUserName={setUserName}
            isEnterprise={isEnterprise}
          />
        )}
      </div>
    </>
  );
};

SignUpOptions.propTypes = {
  isEnterprise: PropTypes.bool,
  userName: PropTypes.string,
  setUserName: PropTypes.func,
};

export default SignUpOptions;
