/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://ow5367tbxbardj4c7idinfi7my.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-pyo5wbrklnb55cfwn5a5abpwf4",
    "aws_cognito_identity_pool_id": "us-east-1:457bde23-4665-45e2-8745-de0d747d6697",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_7SVd52R49",
    "aws_user_pools_web_client_id": "7iaa9kof2gk91v88vj5n30gv4e",
    "oauth": {
        "domain": "torc-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000/,https://admin-dev.opentorc.com/,https://admin-dev.torc.dev/,https://login-dev.torc.dev,https://platform-dev.opentorc.com/,https://platform-dev.torc.dev/,https://search-dev.opentorc.com/,https://search-dev.torc.dev/",
        "redirectSignOut": "http://localhost:3000/,https://admin-dev.opentorc.com/,https://admin-dev.torc.dev/,https://login-dev.torc.dev,https://platform-dev.opentorc.com/,https://platform-dev.torc.dev/,https://search-dev.opentorc.com/,https://search-dev.torc.dev/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "GithubToken-develop",
            "region": "us-east-1"
        },
        {
            "tableName": "Badge-develop",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "torcidentitydbaf2ff0ce664e0ba096a94868fde87293435-develop",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
