import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

const FloatingIconModal = ({
  children,
  className,
  icon,
  loadOpened,
  timeout = 1000,
}) => {
  const [isOpen, setIsOpen] = useState();
  const floatingIconModal = useRef();

  useEffect(() => {
    if (loadOpened) {
      setTimeout(() => {
        setIsOpen(true);
      }, timeout);
    } else {
      setIsOpen(false);
    }
  }, [loadOpened, timeout]);

  return (
    <div
      ref={floatingIconModal}
      className={classNames(
        "fixed bottom-6 right-2 sm:right-4 z-10",
        { invisible: isOpen === undefined },
        { visible: isOpen !== undefined },
        className
      )}
    >
      <div
        className={classNames(
          "static transition-all ease-in-out duration-500",
          { "-translate-y-0": isOpen },
          { "translate-y-3/4": !isOpen }
        )}
      >
        <div
          className={classNames("absolute top-0 right-0", {
            "!-top-12": isOpen,
          })}
        >
          <div
            className={classNames(
              { "w-[64px] h-[64px]": !isOpen },
              {
                "text-lg text-white h-10 w-10 rounded-full bg-gray-400 hover:bg-gray-500":
                  isOpen,
              }
            )}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <div
              className={classNames(
                "transition-all ease-in-out flex justify-center items-center w-full h-full",
                {
                  "opacity-100 duration-700": isOpen,
                },
                {
                  "opacity-0 duration-500": !isOpen,
                }
              )}
            >
              X
            </div>
            <div
              className={classNames(
                "transition-all ease-in-out -translate-y-full",
                {
                  "opacity-100 duration-700": !isOpen,
                },
                {
                  "opacity-0 duration-500": isOpen,
                }
              )}
            >
              {icon}
            </div>
          </div>
        </div>
        <div
          className={classNames(
            "rounded-sm bg-white shadow",
            { "opacity-100 visible": isOpen },
            { "opacity-0 invisible": !isOpen }
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default FloatingIconModal;
