import classNames from "classnames";

export default function AssessmentScore({
  reportLink = "",
  finalScore,
  className = "",
}) {
  if (reportLink && finalScore) {
    return (
      <a
        href={reportLink}
        target="_blank"
        rel="noreferrer"
        className={classNames("text-blue-600 hover:underline", className)}
      >
        {finalScore}
      </a>
    );
  } else if (finalScore && finalScore !== -1) {
    return <>{finalScore}</>;
  }

  return <>{"N/A"}</>;
}
