import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "semantic-ui-react";

import Header from "components/Header";
import Footer from "components/Footer";
import { FreelancerDashboard } from "components/FreelancerDashboard";
import { CustomerDashboard } from "components/CustomerDashboard";

import { useAuth } from "GlobalAuthContext";
import { USER_TYPES } from "lookup";

import "./index.css";

const Dashboard = () => {
  const auth = useAuth();
  const history = useHistory();
  const [appsyncUser, setAppsyncUser] = useState(null);

  useEffect(() => {
    document.title = `${auth.user.username} Torc Member Dashboard`;
    setAppsyncUser(auth.user);

    const retUrl = sessionStorage.getItem("retUrl") ?? null;

    if (retUrl) {
      sessionStorage.clear("retUrl");
      history.push(JSON.parse(retUrl));
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <>
      <Header />
      <Container className="dashboard">
        {(appsyncUser?.userType === USER_TYPES.FREELANCER ||
          appsyncUser?.userType === USER_TYPES.TORC_RECRUITER) && (
          <FreelancerDashboard appsyncUser={appsyncUser} />
        )}
        {appsyncUser?.userType === USER_TYPES.CUSTOMER && (
          <CustomerDashboard appsyncUser={appsyncUser} />
        )}
      </Container>
      <Footer />
    </>
  );
};

export default Dashboard;
