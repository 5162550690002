export const PERIODS = [
  {
    label: "No Notice Period",
    value: 0,
  },
  {
    label: "15 Days",
    value: 15,
  },
  {
    label: "30 Days",
    value: 30,
  },
  {
    label: "60 Days",
    value: 60,
  },
  {
    label: "90 Days",
    value: 90,
  },
];
