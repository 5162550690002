import { API, graphqlOperation } from "aws-amplify";
import { updateMatch } from "graphql/mutations";

const updateMatchRecord = async (applicationId, jobOpportunityId, payload) => {
  await API.graphql(
    graphqlOperation(updateMatch, {
      input: Object.assign({ jobOpportunityId, applicationId }, payload),
    })
  );
};

const updateRating = async (
  application,
  jobOpportunityId,
  selectedRating,
  resetReasonsForRating
) => {
  const ratingValue =
    application.rating === selectedRating ? null : selectedRating;

  if (!ratingValue) {
    return;
  }

  const attrs = {
    rating: selectedRating,
  };

  if (resetReasonsForRating) {
    // reset reasonsForRating if customer changes his mind and is still calibrating
    // reasonsForRating history is being tracked
    attrs.reasonsForRating = null;
  }

  await updateMatchRecord(application.applicationId, jobOpportunityId, attrs);
  return attrs;
};

const functionsToExport = {
  updateMatchRecord,
  updateRating,
};

export default functionsToExport;
