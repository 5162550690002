const LOCATION_UPDATED = "LOCATION_UPDATED";
const TAGLINE_UPDATED = "TAGLINE_UPDATED";
const BIO_UPDATED = "BIO_UPDATED";
const SOCIAL_PROFILE_UPDATED = "SOCIAL_PROFILE_UPDATED";
const EXISTING_DATA_FETCHED = "EXISTING_DATA_FETCHED";
const ROLE_UPDATED = "ROLE_UPDATED";

export {
  BIO_UPDATED,
  EXISTING_DATA_FETCHED,
  LOCATION_UPDATED,
  SOCIAL_PROFILE_UPDATED,
  TAGLINE_UPDATED,
  ROLE_UPDATED,
};
