import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Search } from "semantic-ui-react";
import { ReactComponent as Clear } from "images/new/5-icon_close.svg";
import Spinner from "components/Spinner";
import classNames from "classnames";

const SearchField = ({
  input,
  loading,
  value,
  onSearchChange,
  onResultSelect,
  results,
  resultRenderer,
  className,
  containerClassName,
  fluid,
  debounce,
  label,
  disableInputSearch,
  placeholder,
}) => {
  const [query, setQuery] = useState(value);
  const [isLoading, setIsLoading] = useState(loading);

  const skillSearchTimeoutRef = useRef();

  const handleSearchChange = useCallback((e, data) => {
    clearTimeout(skillSearchTimeoutRef.current);

    setQuery(data.value);
    setIsLoading(true);

    skillSearchTimeoutRef.current = setTimeout(async () => {
      await onSearchChange(e, data);
      setIsLoading(false);
    }, debounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResultSelect = useCallback(
    (e, data) => {
      setQuery("");
      onResultSelect(e, data);
    },
    [onResultSelect]
  );

  return (
    <div
      className={classNames(
        "relative bg-brandSecondary-100 flex items-center md:w-2/3 w-full rounded-sm",
        containerClassName
      )}
    >
      {label && (
        <label
          className={`b4-bold block text-black pr-2 absolute top-[-14px] left-[-2px] bg-white capitalize`}
        >
          {label}
        </label>
      )}
      <Search
        placeholder={placeholder}
        input={input}
        disabled={disableInputSearch}
        loading={isLoading}
        value={query}
        onSearchChange={handleSearchChange}
        onResultSelect={handleResultSelect}
        results={results}
        resultRenderer={resultRenderer}
        noResultsMessage={isLoading ? "Loading results..." : "Start typing"}
        showNoResults={false}
        className={className}
        fluid={fluid}
      />
      {isLoading ? (
        <Spinner className="absolute right-4" />
      ) : (
        query.length > 0 && (
          <Clear
            className="cursor-pointer absolute right-4"
            onClick={() => setQuery("")}
          />
        )
      )}
    </div>
  );
};

SearchField.propTypes = {
  input: PropTypes.object,
  loading: PropTypes.bool,
  disableInputSearch: PropTypes.bool,
  value: PropTypes.string,
  onStartSearch: PropTypes.func,
  onSearchChange: PropTypes.func,
  onResultSelect: PropTypes.func,
  results: PropTypes.array,
  resultRenderer: PropTypes.func,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  fluid: PropTypes.bool,
  debounce: PropTypes.number,
};

SearchField.defaultProps = {
  input: { icon: "search", fluid: true },
  loading: false,
  disableInputSearch: false,
  value: "",
  onStartSearch: () => {},
  onSearchChange: () => {},
  onResultSelect: () => {},
  results: [],
  resultRenderer: () => {},
  className: "",
  containerClassName: "",
  fluid: true,
  debounce: 300,
};

export default SearchField;
