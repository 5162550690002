import { useEffect, useState } from "react";
import YellowBox from "../../shared/YellowBox";
import { AVAILABILITIES } from "./availabilities";
import classNames from "classnames";

const Availability = ({
  className,
  availabilityRef,
  user,
  save,
  setDisabled,
  isCurrent,
  setReminderText,
}) => {
  const [selectedAvailability, setSelectedAvailability] = useState(
    user.availability
  );

  const onAvailableChange = async (value) => {
    setSelectedAvailability(value);
    save({ availability: value }, true);
  };

  useEffect(() => {
    if (!isCurrent) return;
    const isDisabledState = selectedAvailability === null;
    setDisabled(isDisabledState);
    if (isDisabledState) {
      setReminderText("Select one option");
    } else {
      setReminderText("");
    }
  }, [selectedAvailability, setDisabled, isCurrent]);

  return (
    <div
      className={classNames("flex flex-col items-start", className)}
      ref={availabilityRef}
    >
      <div className="b1">Your current availability for new jobs.</div>
      <div className="b2 mb-8">
        Select the option that best matches you today.
      </div>
      <div className="flex flex-col sm:flex-row gap-4 sm:gap-8 justify-start flex-wrap">
        {AVAILABILITIES.map((availability, index) => (
          <YellowBox
            key={index}
            handleClick={() => onAvailableChange(availability.value)}
            selected={selectedAvailability === availability.value}
            popover={availability.popover}
          >
            {availability.label}
          </YellowBox>
        ))}
      </div>
    </div>
  );
};

export default Availability;
