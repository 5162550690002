import { API, graphqlOperation } from "aws-amplify";
import { createReferral } from "graphql/mutations";
import { listJobReferralsByJobOpportunity } from "graphql/queries";
import { REFERRAL_TYPES } from "lookup";

const createRecord = async (payload) => {
  const isJobReferral =
    payload.referralType === REFERRAL_TYPES.JOB ||
    payload.referralType === REFERRAL_TYPES.PUBLIC_JOB;

  if (
    (isJobReferral && !payload.jobOpportunityId && !payload.jobReferrerCode) ||
    (!isJobReferral && payload.referralType !== REFERRAL_TYPES.USER)
  ) {
    return;
  }

  try {
    await API.graphql(
      graphqlOperation(createReferral, {
        input: {
          ...payload,
        },
      })
    );
    if (isJobReferral) {
      localStorage.removeItem("jobReferral");
    }
    if (payload.referralType === REFERRAL_TYPES.USER) {
      localStorage.removeItem("userReferral");
    }
  } catch (err) {
    console.log("something went wrong during createReferral");
    console.log(err);
  }
};

const listJobOpportunityReferrals = async (jobOpportunityId, payload) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(listJobReferralsByJobOpportunity, {
        jobOpportunityId,
        ...payload,
      })
    );

    return data?.listJobReferralsByJobOpportunity?.items;
  } catch (error) {
    console.error("Error fetching users job referrals:", error);
    return [];
  }
};

const functionsToExport = {
  createRecord,
  listJobOpportunityReferrals,
};

export default functionsToExport;
