import classNames from "classnames";

import Chip from "components/Chip";
import ImageViewer from "components/ImageViewer";

import placeholderPicture from "images/placeholderProfile.png";

import "./index.module.css";
import { getScoreClasses } from "../helper";

const HeadShot = ({ headshotKey, score, className, chipClassName }) => {
  const scoreClasses = getScoreClasses(score, 90);

  return (
    <div
      className={classNames(
        "static h-[64px] w-[64px] sm:w-[75px] sm:h-[75px] rounded-full p-1 -rotate-45",
        scoreClasses.bgHeadShot,
        className
      )}
    >
      <div className="static rotate-45 w-full h-full">
        <div className="overflow-hidden rounded-full h-full profile--image">
          <ImageViewer
            objectKey={headshotKey}
            placeholder={
              <img
                className="bg-grey-800"
                src={placeholderPicture}
                alt="Placeholder"
              />
            }
            className="h-full"
          />
        </div>
        {score >= 0 && (
          <div className="absolute -bottom-1 -right-2">
            <Chip
              value={`${Math.trunc(score)}%`}
              className={classNames(
                "text-[10px] !text-white border-none font-semibold h-6 w-6 justify-center",
                scoreClasses.bgChip,
                chipClassName
              )}
              valueClassName="!px-0"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeadShot;
