export function formatLanguageLevel(level) {
  if (!level) {
    return "";
  }

  let formattedLevel = "";
  switch (level) {
    case "BASIC":
      formattedLevel = "Basic";
      break;
    case "CONVERSATIONAL":
      formattedLevel = "Conversational";
      break;
    case "FLUENT":
      formattedLevel = "Fluent";
      break;
    case "FULLY_FLUENT":
      formattedLevel = "Fully Fluent";
      break;
    // for backwards compatability
    case "NATIVE":
      formattedLevel = "Fully Fluent";
      break;
    default:
      throw new Error("Unknown language level");
  }
  return formattedLevel;
}
