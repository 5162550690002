import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as CodealikeLogo } from "images/codealike-vertical-white-wordmark.svg";
import CodealikeConnect from "pages/Profile/Wizard/StackAndSkills/ConnectAccounts/CodealikeConnect";

const CustomLink = ({ alt, to, target = "_blank", label }) => (
  <Link className="w-full sm:w-fit" alt={alt} to={to} target={target}>
    <span className="bg-mustard text-xs sm:text-sm lg:text-base sm:text-black uppercase py-2 px-4 rounded-full font-bold inline-block w-full text-center">
      {label}
    </span>
  </Link>
);

const CodealikeWidget = ({ appsyncUser, dispatchUserUpdated }) => {
  return (
    <div className="flex flex-col p-2 min-h-[300px] mt-8 w-full lg:w-1/2 pr-0">
      <h5 className="ml-2 font-bold">Codealike</h5>
      <div className="p-2 w-full">
        <div className="bg-black p-4 flex items-center justify-between">
          <CodealikeLogo className="h-17 -ml-4" title="Codealike" />
          <div className="flex gap-2.5 flex-wrap w-1/2 sm:w-fit justify-end">
            <CustomLink
              label="Full Report"
              alt="Full Report"
              to="/external/full-report"
            />
            <CustomLink
              label="Leaderboard"
              alt="leaderboard"
              to="/external/leaderboard"
            />
            <CustomLink
              label="Torc Store"
              alt="Torc store"
              to="/external/torc-store"
            />
          </div>
        </div>
        <div className="bg-white h-[48vh]">
          {appsyncUser.codealike?.handle ? (
            <iframe
              title="iframe"
              src={`https://lookerstudio.google.com/embed/reporting/df7229a8-7eee-4900-9798-99f3fefc4c13/page/JHBkD?params=%7B%22df3%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${appsyncUser.id}%22%7D`}
              frameBorder="0"
              height="100%"
              width="100%"
            />
          ) : (
            <CodealikeConnect
              user={appsyncUser}
              dispatchUserUpdated={dispatchUserUpdated}
              className="h-full"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CodealikeWidget;
