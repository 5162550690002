const COVER_PHOTO_SELECTED = "COVER_PHOTO_SELECTED";
const PROFILE_PIC_SELECTED = "PROFILE_PIC_SELECTED";
const PICTURES_FETCHED = "PICTURES_FETCHED";
const PROFILE_PIC_DELETED = "PROFILE_PIC_DELETED";
const COVER_PHOTO_DELETED = "COVER_PHOTO_DELETED";
const PHOTO_DELETED_COMPLETED = "PHOTO_DELETED_COMPLETED";

export {
  COVER_PHOTO_SELECTED,
  PICTURES_FETCHED,
  PROFILE_PIC_SELECTED,
  PROFILE_PIC_DELETED,
  COVER_PHOTO_DELETED,
  PHOTO_DELETED_COMPLETED,
};
