import classNames from "classnames";
import HoverTooltip from "components/HoverTooltip";
import React from "react";

const skillColor = {
  low: "text-zestygreen",
  medium: "text-orange",
  high: "text-electricBlue",
};

const SkillsChips = ({
  title = "Skills",
  skills,
  className,
  styles,
  wrapperClassName,
  handleSkillClick,
  handleSkillRemoveClick,
}) => {
  return (
    <section className={(classNames, className)}>
      {title && <h2 className="text-2xl mb-6">{title}</h2>}

      <div
        className={classNames(
          "flex flex-wrap gap-3",
          styles ? styles["skills-wrapper"] : "",
          wrapperClassName
        )}
      >
        {skills
          ?.sort((a, b) => {
            const order = {
              high: 0,
              medium: 1,
              low: 2,
            };
            return order[a.experience] - order[b.experience];
          })
          .map((skill, idx) => (
            <HoverTooltip
              key={`skill-${idx}`}
              tracking={{ type: "skill", skillName: skill.name }}
              hoverText={
                <div className="mx-auto bg-white rounded-[16px] shadow-md relative">
                  <div className="text-center m-2">
                    <p className="text-gray-800">
                      {skill.experience ? (
                        <>
                          <span
                            className={classNames(
                              "font-bold",
                              skillColor[skill.experience]
                            )}
                          >
                            {skill.experience.charAt(0).toUpperCase() +
                              skill.experience.slice(1)}
                          </span>{" "}
                          experience{" "}
                        </>
                      ) : (
                        "Experienced "
                      )}
                      in <span className="font-bold">{skill.name}</span>
                    </p>
                  </div>
                </div>
              }
            >
              <div
                key={`skill-${idx}`}
                className={classNames(
                  "inline-block mr-2 mb-2 px-3 py-2 text-center rounded-full text-sm font-medium text-cyan-800 bg-cyan-50",
                  "shadow-xs hover:shadow-xs hover:bg-cyan-100 transition-shadow duration-200"
                )}
              >
                <span
                  onClick={() => handleSkillClick && handleSkillClick(skill)}
                >
                  {skill.name}
                </span>

                {handleSkillRemoveClick && (
                  <span
                    className="border-l-2 border-gray-400 ml-2 pl-2"
                    onClick={() => handleSkillRemoveClick(skill)}
                  >
                    x
                  </span>
                )}
              </div>
            </HoverTooltip>
          ))}
      </div>
    </section>
  );
};

export default SkillsChips;
