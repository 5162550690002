import React, { forwardRef, useMemo } from "react";
import classNames from "classnames";
import {
  CandidateGridAssessment,
  CandidateGridBadges,
  CandidateGridBasic,
  CandidateGridCareers,
  CandidateGridCTA,
  CandidateGridCustomLinks,
  CandidateGridLanguages,
  CandidateGridProjects,
  CandidateGridSkills,
  CandidateGridSummary,
} from "./components";
import styles from "./GridView.module.css";
import GithubStats from "components/GithubStats";
import { useAuth } from "GlobalAuthContext";
import { mergeCareersAndPlacements } from "utils/general";

const CandidatedGridView = forwardRef(function CandidatesGridView(
  {
    matches,
    jobCalendarEvents,
    jobOpp,
    updateJobOppMatchState,
    deleteJobOppMatchFromState,
    isAdmin,
    handleRatingClick,
    rateType,
    noteType,
    checkIsReadOnly,
    hideMatchActions,
    setModalDisplay,
    modals,
    showMatchSummary,
  },
  ref
) {
  // 12 sections of data displayed in each column => 12 rows of data
  const sections = useMemo(
    () => new Array(showMatchSummary ? 13 : 12).fill(0),
    [showMatchSummary]
  );
  const auth = useAuth();

  return (
    <div
      className={classNames("flex overflow-x-auto", styles.noScrollbar)}
      ref={ref}
    >
      <table className="border-spacing-0 h-px" cellPadding={0} cellSpacing={0}>
        <tbody className="h-full">
          {sections.map((s, sectionIdx) => (
            <tr key={sectionIdx} className="h-full">
              {matches.map((match, matchIdx) => {
                const { user } = match;

                const {
                  assessments,
                  availability,
                  badges,
                  bio,
                  careers,
                  placements,
                  knownLanguages,
                  location,
                  otherLinks,
                  projectsCaseStudies,
                  skills,
                  tagline,
                  stats,
                } = user;

                const finalCareers = mergeCareersAndPlacements(
                  careers,
                  placements
                );

                const candidateHasMeetings = jobCalendarEvents?.some((e) =>
                  e.title.includes(user.username)
                );

                // For each row, depending on the row number the respective
                // component is displayed
                const sections = [
                  <td key={matchIdx} className="h-full">
                    <CandidateGridBasic
                      user={user}
                      rate={match[rateType]}
                      noteType={noteType}
                      notesIsReadOnly={checkIsReadOnly(match, jobOpp)}
                      jobCalendarEvents={jobCalendarEvents}
                      jobOpportunityId={match.jobOpportunityId}
                      applicationId={match.applicationId}
                    />
                  </td>,
                  <td key={matchIdx} className="h-full">
                    <CandidateGridCTA
                      candidateHasMeetings={candidateHasMeetings}
                      match={match}
                      jobOpp={jobOpp}
                      updateJobOppMatchState={updateJobOppMatchState}
                      deleteJobOppMatchFromState={deleteJobOppMatchFromState}
                      handleRatingClick={handleRatingClick}
                      isReadOnly={checkIsReadOnly(match, jobOpp)}
                      hideMatchActions={hideMatchActions}
                      setModalDisplay={setModalDisplay}
                      modals={modals}
                    />
                  </td>,
                  showMatchSummary && (
                    <td key={matchIdx} className="h-full">
                      <section className="w-[420px] bg-white border-r h-full border-b p-8">
                        <h5 className="font-nexa font-bold text-xl mb-4">
                          Candidate Summary
                        </h5>

                        <p className="whitespace-pre-wrap text-gray-700">
                          {match.customerSummary ? match.customerSummary : "-"}
                        </p>
                      </section>
                    </td>
                  ),
                  <td key={matchIdx} className="h-full">
                    <section className="w-[420px] bg-white border-r h-full border-b p-8">
                      <h5 className="font-nexa font-bold text-xl mb-4">Bio</h5>
                      <h6 className="font-nexa-light font-semibold text-lg mb-2">
                        {tagline}
                      </h6>
                      <p className="whitespace-pre-wrap text-gray-700">{bio}</p>
                    </section>
                  </td>,
                  <td key={matchIdx} className="h-full">
                    <CandidateGridSummary
                      matchFieldHistory={match.fieldHistory}
                      location={location}
                      careers={finalCareers}
                      availability={availability}
                    />
                  </td>,
                  <td key={matchIdx} className="h-full">
                    <CandidateGridBadges badges={badges} />
                  </td>,
                  <td key={matchIdx} className="h-full">
                    <CandidateGridSkills skills={skills} />
                  </td>,
                  <td key={matchIdx} className="h-full">
                    <CandidateGridLanguages knownLanguages={knownLanguages} />
                  </td>,
                  <td key={matchIdx} className="h-full">
                    <CandidateGridCustomLinks links={otherLinks} />
                  </td>,
                  <td key={matchIdx} className="h-full">
                    <GithubStats stats={stats} isVertical={true} />
                  </td>,
                  <td key={matchIdx} className="h-full">
                    <CandidateGridCareers careers={finalCareers} />
                  </td>,
                  <td key={matchIdx} className="h-full">
                    <CandidateGridProjects
                      projectsCaseStudies={projectsCaseStudies}
                    />
                  </td>,
                  <td key={matchIdx} className="h-full">
                    <CandidateGridAssessment
                      assessments={assessments}
                      minScore={isAdmin || auth?.user?.id === user?.id ? 0 : 70}
                    />
                  </td>,
                ].filter((e) => e);

                return sections[sectionIdx];
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default CandidatedGridView;
