export const getScoreClasses = (score, offset = 0) => {
  const mappedScore = score - offset;
  const ratio = (100 - offset) / 4;
  if (mappedScore > ratio * 3) {
    return {
      bgHeadShot: "bg-gradient-to-r from-[#36BB6F] to-[#0091B2]",
      bgChip: "bg-[#42BA73]",
      toolTip: "!bg-[#ECF8F1] text-[#42BA73]",
    };
  } else if (mappedScore > ratio * 2 && mappedScore <= ratio * 3) {
    return {
      bgHeadShot: "bg-gradient-to-r from-[#1985AD] to-[#6E21CB33]",
      bgChip: "bg-[#138BAF]",
      toolTip: "!bg-[#DCEEF3] text-[#138BAF]",
    };
  } else if (mappedScore > ratio && mappedScore <= ratio * 2) {
    return {
      bgHeadShot: "bg-gradient-to-r from-[#EF933E] to-[#EFC5184D]",
      bgChip: "bg-[#EE923E]",
      toolTip: "!bg-[#FDF4EC] text-[#EE923E]",
    };
  } else if (mappedScore < ratio) {
    return {
      bgHeadShot: "bg-gradient-to-r from-[#C8595A] to-[#EE8D2333]",
      bgChip: "bg-[#DE4C2F]",
      toolTip: "!bg-[#FAE4E0] text-[#DE4C2F]",
    };
  } else {
    return {
      bgHeadShot: "bg-transparent",
      bgChip: "bg-transparent",
      toolTip: "",
    };
  }
};
