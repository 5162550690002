import { useEffect, useReducer } from "react";

import "../skills.css";
import { languages } from "./languages";
import SearchField from "components/SearchField";
import YellowBox from "../../shared/YellowBox";
import { ReactComponent as Trash } from "images/new/trash.svg";
import classNames from "classnames";

const LANGUAGE_SEARCH_INITIAL_STATE = {
  languageSearchTerm: "",
  languageSearchResults: [],
};

function languageSearchReducer(state, action) {
  switch (action.type) {
    case "CLEAN_QUERY":
      return LANGUAGE_SEARCH_INITIAL_STATE;
    case "SEARCH": {
      const newSearch = languages.filter(
        (lang) =>
          lang.name.toLowerCase().includes(action.search.toLowerCase()) ||
          lang.iso.includes(action.search.toLowerCase())
      );
      return {
        ...state,
        languageSearchResults: newSearch,
        languageSearchTerm: action.search,
      };
    }
    default:
      return state;
  }
}

const recommendedLanguages = ["Spanish", "Portuguese", "Italian", "Chinese"];

function Languages({
  user,
  className,
  langRef,
  userLanguages,
  setUserLanguages,
  save,
  setDisabled,
  setReminderText,
}) {
  const [languageSearchState, languageSearchDispatch] = useReducer(
    languageSearchReducer,
    LANGUAGE_SEARCH_INITIAL_STATE
  );
  const { languageSearchResults, languageSearchTerm } = languageSearchState;

  const triggerLanguageSearch = (_event, data) => {
    languageSearchDispatch({
      type: "SEARCH",
      search: data.value,
    });
  };

  const onLanguageSelect = (event, data) => {
    const { name } = data.result;
    const languageAlreadySet = userLanguages.find(
      (lang) => lang.language === name
    );
    if (languageAlreadySet) return;
    languageSearchDispatch({
      type: "SEARCH",
      search: "",
    });
    setUserLanguages((prev) => [
      ...prev,
      {
        language: name,
        level: undefined,
      },
    ]);
  };

  const selectLanguageLevel = (language, level) => {
    const languageIdx = userLanguages.findIndex(
      (ele) => ele.language === language
    );
    userLanguages[languageIdx].level = level;
    setUserLanguages((prev) => [...prev]);
  };

  const renderLanguageSkillResults = ({ name }) => (
    <div className="title">{name}</div>
  );

  useEffect(() => {
    setDisabled(false);
    if (
      JSON.stringify(userLanguages) === JSON.stringify(user.knownLanguages) ||
      userLanguages.length === 0
    ) {
      return setReminderText("");
    }
    if (userLanguages.find((l) => !l.level)) {
      setDisabled(true);
      return setReminderText(
        "Please select a proficiency level for all your selected languages."
      );
    }
    setReminderText("");
    save({ knownLanguages: userLanguages }, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLanguages]);

  const langExperienceComponent = (label, lang, level) => {
    return (
      <YellowBox
        handleClick={() => selectLanguageLevel(lang.language, level)}
        selected={lang.level === level}
        containerClassName="max-w-full"
      >
        {label}
      </YellowBox>
    );
  };

  return (
    <div className={classNames("!py-5", className)} ref={langRef}>
      <div className="flex flex-col gap-5">
        <div className="b1">Add your languages and proficiency level.</div>
        <p className="b3 leading-4 md:leading-6">
          Include all languages in which you are comfortable communicating with
          customers and teams.
        </p>
        <SearchField
          value={languageSearchTerm}
          onSearchChange={triggerLanguageSearch}
          onResultSelect={onLanguageSelect}
          results={languageSearchResults}
          resultRenderer={renderLanguageSkillResults}
          className="searchBar"
        />
      </div>

      <div className="sm:flex gap-[111px]">
        <div className="mt-5">
          <div className="b2 mb-4">Suggested languages</div>
          {recommendedLanguages.map((language) => {
            return (
              <div
                key={language}
                className="font-nexa-regular text-lg leading-[28px] tracking-[-0.69px] mb-6 cursor-pointer"
                onClick={(e) =>
                  onLanguageSelect(e, { result: { name: language } })
                }
              >
                {language}
              </div>
            );
          })}
        </div>

        {userLanguages?.length > 0 && (
          <div className="mt-5">
            <div className="b2 mb-4">
              What is your level of proficiency with this language
            </div>
            <div className="flex flex-wrap gap-5 w-fit sm:gap-[52px]">
              {userLanguages.map((l, index) => (
                <div
                  key={index}
                  className="rounded-sm border border-brandSecondary min-w-[130px] w-full flex-1 max-w-[164px]"
                >
                  <div className="py-[18px] px-4 relative b5 text-center flex items-center gap-4 justify-center flex-col">
                    <p className="font-rubik-bold text-base">{l.language}</p>
                    {l.language !== "English" && (
                      <button
                        className="rounded-xs px-1 py-[5px] bg-brandPrimary andPrimary absolute top-[-6px] right-[-4px]"
                        onClick={() =>
                          setUserLanguages((prev) =>
                            prev.filter((el) => el.language !== l.language)
                          )
                        }
                      >
                        <Trash className="text-current" />
                      </button>
                    )}
                    {langExperienceComponent("Basic", l, "BASIC")}
                    {langExperienceComponent(
                      "Conversational",
                      l,
                      "CONVERSATIONAL"
                    )}
                    {langExperienceComponent("Fluent", l, "FLUENT")}
                    {langExperienceComponent("Fully Fluent", l, "FULLY_FLUENT")}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Languages;
