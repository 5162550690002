import { useEffect, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import Button from "components/FormComponents/Button";
import CustomToggle from "components/CustomToggle";
import JobMatchCard from "components/JobMatchCard";

import "./index.css";

export const JobWidget = ({
  title,
  jobElements = [],
  description,
  className,
  titleTextColor,
  condensedLimit,
  skillsToMatch = [],
  hideMatchSkillsButton,
  showSeeAllJobsLinkButton,
}) => {
  const [jobOpps, setJobOpps] = useState(jobElements);
  const [matchSkillsEnabled, setMatchSkillsEnabled] = useState(true);

  useEffect(() => {
    if (skillsToMatch.length > 0) {
      if (matchSkillsEnabled) {
        setJobOpps((prev) =>
          prev.filter((element) => {
            let job;
            if (element.applicationId) {
              job = element.jobOpportunity;
            } else {
              job = element;
            }

            return [...(job.skills || []), ...(job.optionalSkills || [])]?.some(
              ({ name }) => skillsToMatch.includes(name)
            );
          })
        );
      } else {
        setJobOpps(jobElements);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchSkillsEnabled]);

  if (jobElements.length === 0) {
    return null;
  }

  const elements =
    condensedLimit && jobOpps.length > condensedLimit
      ? jobOpps.slice(0, condensedLimit)
      : jobOpps;

  const skillsMatchAllJobSkills = () =>
    matchSkillsEnabled &&
    jobElements.length ===
      jobElements.filter((element) => {
        let job;
        if (element.applicationId) {
          job = element.jobOpportunity;
        } else {
          job = element;
        }

        return [...(job.skills || []), ...(job.optionalSkills || [])]?.some(
          ({ name }) => skillsToMatch.includes(name)
        );
      }).length;

  return (
    <div className={classNames("p-2 w-full lg:w-1/2 jobwidget", className)}>
      <div className="border-2 bg-white h-full shadow-xs p-4">
        <div className="flex justify-between mb-2 flex-wrap gap-y-2 items-start">
          <h5 className={classNames("font-bold", titleTextColor)}>{title}</h5>
          {!!skillsToMatch.length &&
            !hideMatchSkillsButton &&
            !skillsMatchAllJobSkills() && (
              <CustomToggle
                checked={matchSkillsEnabled}
                leftLabel={<span className="font-semibold">Show All</span>}
                rightLabel={
                  <span className="font-semibold">Match My Skills</span>
                }
                onChange={() => setMatchSkillsEnabled((prev) => !prev)}
              />
            )}
        </div>
        <p className="xl:w-[68%] md:w-[90%] header">{description}</p>
        {elements.map((element, index) => {
          let match;
          let jobOpportunity;
          let linkState;

          if (element.applicationId) {
            match = element;
            jobOpportunity = match.jobOpportunity;
          } else {
            jobOpportunity = element;
          }

          const { id } = jobOpportunity;

          if (match) {
            linkState = { job: jobOpportunity, match: element };
          }

          return (
            <section key={`${id}_${index}`} className="mt-6">
              <JobMatchCard
                job={jobOpportunity}
                jobReferral={element.jobReferral}
                matchStatus={match?.status}
                linkState={linkState}
              />
            </section>
          );
        })}
        {elements.length === 0 && (
          <p className="px-2 font-medium text-red-500 text-base my-6">
            It looks like there are no Hot Jobs matching your skills at the
            moment.
          </p>
        )}
        {((condensedLimit && jobOpps.length > condensedLimit) ||
          showSeeAllJobsLinkButton) && (
          <div className="mt-14 text-center">
            <Link to="/jobs/matches">
              <Button className="font-rubik-regular" bgColor="bg-yellow-200">
                {showSeeAllJobsLinkButton ? "See All Jobs" : "More"}
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
