import { useEffect, useMemo, useState } from "react";
import { API } from "aws-amplify";

import CustomCard from "components/CustomCard";
import { ReactComponent as CodealikeLogo } from "images/codealike-vertical-white-wordmark.svg";
import { getUser } from "graphql/queries";
import { onUpdateUser } from "graphql/subscriptions";

const INFO_MESSAGES = {
  hasCodealike:
    "Codealike brings a human-centered approach to developer productivity and helps you maximize your career and earning potential.",
  noCodealike:
    "Are you ready to truly understand your productivity and code like the world’s highest performing developers?",
  haClickedOnLink: (
    <div className="mt-4 text-blue-700">
      <p className="b4">Once you created your account</p>
      <p className="b4">Your data will be synced shortly</p>
    </div>
  ),
};

const renderReminderMessage = (user) => (
  <p className="mt-2 b4">
    Remember to sign-up with your torc account email:{" "}
    <span className="text-blue-700">{user.email}</span>
  </p>
);

const CodealikeConnect = ({
  user,
  dispatchUserUpdated,
  className,
  customCard,
}) => {
  const [infoMessage, setInfoMessage] = useState(
    user.codealike?.handle ? null : renderReminderMessage(user)
  );

  const codealikeHandleChanged = (user, handle) => {
    user.codealike = {
      ...user.codealike,
      handle,
    };
    dispatchUserUpdated(user);
  };

  const onUpdateUserSub = useMemo(() => {
    return API.graphql({
      query: onUpdateUser,
      variables: { id: user.id },
    }).subscribe({
      next: ({ value }) => {
        const userUpdated = value.data.onUpdateUser;

        if (
          userUpdated?.codealike &&
          userUpdated.codealike.handle !== user.codealike?.handle
        ) {
          codealikeHandleChanged(user, userUpdated.codealike.handle);
          if (userUpdated.codealike.handle) {
            setInfoMessage(null);
          } else {
            setInfoMessage(renderReminderMessage(user));
          }
        }
      },
      error: (error) => console.log("subscribe error", error),
    });
    /* eslint-disable */
  }, []);

  useEffect(() => {
    (async () => {
      if (!user.codealike?.handle) {
        try {
          const { data } = await API.graphql({
            query: getUser,
            variables: { id: user.id },
          });
          const fetchedUser = data.getUser;

          if (fetchedUser.codealike?.handle) {
            codealikeHandleChanged(user, fetchedUser.codealike?.handle);
            setInfoMessage(null);
          }
        } catch (err) {
          console.log("Something went wrong getUser", err);
        }
      }
    })();

    return () => {
      onUpdateUserSub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      {customCard ? (
        <CustomCard
          label="Codealike"
          title="Measure value, not hours."
          body={
            user.codealike?.handle ? (
              INFO_MESSAGES.hasCodealike
            ) : (
              <div>{INFO_MESSAGES.noCodealike}</div>
            )
          }
          action={
            <a
              href={
                user.codealike?.handle
                  ? `https://lookerstudio.google.com/u/0/reporting/dd8c4836-f8a0-4169-a794-0791d1d68add/page/NODdD?params=%7B%22df110%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${user.id}%22%7D`
                  : "https://codealike.com/Account/Register"
              }
              target="_blank"
              rel="noopener noreferrer"
              className="b3 text-brandSecondary "
            >
              {user.codealike?.handle
                ? "Checkout your report!"
                : "Create Your Account"}
            </a>
          }
          onClick={() => setInfoMessage(INFO_MESSAGES.haClickedOnLink)}
          infoMessage={infoMessage}
          icon={<CodealikeLogo className="w-[124px]" />}
        />
      ) : (
        <div className="flex flex-col items-start justify-center h-full p-8 gap-y-4">
          <p className="text-lg lg:text-xl font-rubik-regular tracking-wider">
            {INFO_MESSAGES.noCodealike}
          </p>
          <div className="cursor-pointer text-blue-500 tracking-wider hover:underline text-lg lg:text-xl font-rubik-regular">
            <a
              onClick={() => setInfoMessage(INFO_MESSAGES.haClickedOnLink)}
              href="https://codealike.com/Account/Register"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Started!
            </a>
          </div>
          {infoMessage}
        </div>
      )}
    </div>
  );
};

export default CodealikeConnect;
