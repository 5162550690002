import classNames from "classnames";
import InfoPopover from "components/FormComponentsNew/InfoPopover";

const YellowBox = ({
  handleClick,
  children,
  popover,
  selected,
  className,
  containerClassName,
}) => {
  return (
    <div
      className={classNames(
        "flex items-center self-center",
        containerClassName
      )}
    >
      <div
        onClick={handleClick}
        className={classNames(
          "b4-bold text-xs border rounded-sm border-brandPrimary hover:bg-brandPrimary w-[132px] h-[42px] flex items-center justify-center cursor-pointer",
          {
            "bg-brandPrimary": selected,
          },
          className
        )}
      >
        {children}
      </div>
      {popover && <InfoPopover>{popover}</InfoPopover>}
    </div>
  );
};

export default YellowBox;
