import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useEffect, useState } from "react";
import DefaultInput from "components/FormInputs/DefaultInput";
import InfoPopover from "components/FormComponentsNew/InfoPopover";
import { SALARY_CONFIG } from "lookup";
import classNames from "classnames";
import { isEqual } from "lodash";

const isValidRate = (value) => {
  if (!value) {
    return true;
  }

  if (!Number.isInteger(value) || String(value).includes(".")) {
    return false;
  }
  if (value && value > 0 && value <= SALARY_CONFIG.MAX_RATE_PER_HOUR) {
    return true;
  }
  return false;
};

const isValidSalary = (value) => {
  if (!value) {
    return true;
  }
  if (!Number.isInteger(value) || String(value).includes(".")) {
    return false;
  }
  if (value && value > 0 && value <= SALARY_CONFIG.MAX_SALARY) {
    return true;
  }
  return false;
};

const rateSchema = Yup.object().shape({
  ratePerHour: Yup.number()
    // To prevent showing an error about not casting the data type properly
    .transform((value, originalValue) => {
      return originalValue === "" ? undefined : value;
    })
    .max(
      SALARY_CONFIG.MAX_RATE_PER_HOUR,
      "Enter a value less than or equal to $200"
    )
    .min(1, "Enter at least $1")
    .test("integer", "Please enter an integer", isValidRate),
  salary: Yup.number()
    .transform((value, originalValue) => {
      return originalValue === "" ? undefined : value;
    })
    .max(
      SALARY_CONFIG.MAX_SALARY,
      "Enter a value less than or equal to $416,000"
    )
    .min(1, "Enter at least $1"),
});

const renderSectionText = (compensationType) => {
  if (compensationType === "ratePerHour") {
    return (
      <p className="b4-bold mt-4">
        Please enter your requested hourly rate in the box.
        <br />
        As a contractor your rate should factor in taxes, vacations, holidays,
        etc.
        <br />
        <span className="font-normal">
          This value is only used to help match you to the right jobs.
          <br />
          You'll be able to adjust this requesteed rate when you apply to
          specific jobs.
        </span>
      </p>
    );
  }

  return (
    <p className="b4-bold mt-4">
      Please enter your requested yearly salary in the box.
      <br />
      <br />
      <span className="font-normal">
        This value is only used to help match you to the right jobs.
        <br />
        You'll be able to adjust this requesteed salary when you apply to
        specific jobs.
      </span>
    </p>
  );
};

const getMonthlyRate = (compensationType, value) => {
  if (!compensationType || !value) {
    return "";
  }

  if (compensationType === "ratePerHour") {
    return Math.round(value * 173);
  }

  return Math.round(value / 12);
};

const Rate = ({ user, className, rateRef, save, setDisabled }) => {
  const [compensationType, setCompensationType] = useState(() => {
    if (user?.ratePerHour?.value) {
      return "ratePerHour";
    }
    return "salary";
  });

  const methods = useForm({
    resolver: yupResolver(rateSchema),
    defaultValues: {
      ratePerHour: user?.ratePerHour?.value || "",
      salary: user?.salary?.value || "",
    },
    mode: "all",
  });

  const { getFieldState, watch, setValue } = methods;

  const watchedRatePerHourValue = watch("ratePerHour");
  const watchedSalaryValue = watch("salary");

  const handleBlur = ({ target }) => {
    const key = target.id;
    const value = target.value || null;

    if (getFieldState(key).error) {
      return;
    }

    if (value > 0 || value === null) {
      const attrObj =
        value > 0 ? { value: Number(value), currency: "USD" } : null;

      if (key === "ratePerHour") {
        if (isEqual(attrObj, user?.ratePerHour)) {
          return;
        }

        save({ ratePerHour: attrObj }, true);
      } else if (key === "salary") {
        if (isEqual(attrObj, user?.salary)) {
          return;
        }

        save({ salary: attrObj }, true);
      }
    }
  };

  useEffect(() => {
    if (user?.ratePerHour?.value || user?.salary?.value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [user, setDisabled]);

  useEffect(() => {
    if (!watchedRatePerHourValue && !watchedSalaryValue) {
      setDisabled(true);
      return;
    }

    if (compensationType === "ratePerHour") {
      if (!isValidRate(Number(watchedRatePerHourValue))) {
        setDisabled(true);
      }
    } else if (compensationType === "salary") {
      if (!isValidSalary(Number(watchedSalaryValue))) {
        setDisabled(true);
      }
    }
  }, [
    watchedRatePerHourValue,
    watchedSalaryValue,
    compensationType,
    setDisabled,
  ]);

  const handleCompensationTypeChange = (type) => {
    setCompensationType(type);
    setValue("ratePerHour", user?.ratePerHour?.value ?? "", {
      shouldValidate: true,
    });
    setValue("salary", user?.salary?.value ?? "", { shouldValidate: true });
  };

  return (
    <div className={className} ref={rateRef}>
      <div className="b1 mb-2">
        Your expected compensation in U.S. Dollars (USD)
      </div>
      <div className="font-normal mb-2">
        You may enter an hourly rate, a yearly salary, or both.
      </div>
      <div className="border border-gray-300 rounded-full text-gray-400 w-fit">
        <span
          className={classNames(
            "p-2 hover:bg-blue-500 hover:cursor-pointer hover:text-white rounded-l-full text-center font-normal inline-block border-r border-gray-300",
            {
              "bg-blue-500 text-white": compensationType === "ratePerHour",
            }
          )}
          onClick={() => handleCompensationTypeChange("ratePerHour")}
        >
          Hourly Rate
        </span>
        <span
          className={classNames(
            "p-2 hover:bg-blue-500 hover:cursor-pointer hover:text-white rounded-r-full text-center font-normal inline-block border-r border-l border-gray-300",
            {
              "bg-blue-500 text-white": compensationType === "salary",
            }
          )}
          onClick={() => handleCompensationTypeChange("salary")}
        >
          Yearly Salary
        </span>
      </div>

      {renderSectionText(compensationType)}
      <FormProvider {...methods}>
        <form
          className="flex mt-16 gap-8 items-start flex-wrap"
          onBlur={handleBlur}
        >
          {compensationType === "ratePerHour" ? (
            <DefaultInput
              type="number"
              id="ratePerHour"
              label="Rate per Hour (in $)"
              defaultValue={user?.ratePerHour?.value ?? 0}
              value={watchedRatePerHourValue}
            />
          ) : (
            <DefaultInput
              type="number"
              id="salary"
              label="Yearly Salary (in $)"
              defaultValue={user?.salary?.value ?? 0}
              value={watchedSalaryValue}
            />
          )}

          <div className="flex gap-4 items-center">
            <DefaultInput
              type="number"
              id="monthlyRate"
              label="Monthly Equivalent (in $)"
              disabled={true}
              min={1}
              placeholder="0"
              value={getMonthlyRate(
                compensationType,
                compensationType === "ratePerHour"
                  ? watchedRatePerHourValue
                  : watchedSalaryValue
              )}
            />
            <InfoPopover>
              Monthly equiv is calculated as
              <br />
              hourly rate x 173 or salary / 12
            </InfoPopover>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Rate;
