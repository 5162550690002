import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { useAuth } from "GlobalAuthContext";
import Modal from "components/Modal";
import {
  getJobOpportunityAndItsMatchCalibrations,
  getJobOpportunityMatchesCalibrations,
  listMatchNotes,
} from "graphql/queries";

import Footer from "components/Footer";
import Header from "components/Header";
import {
  CALIBRATION_VIRTUAL_STATUS,
  CANDIDATES_VIEW_MODES,
  JOB_APPLICATION_MATCH_RATINGS,
  JOB_APPLICATION_MATCH_STATUS,
  JOB_OPPORTUNITY_STATUSES,
  NOTE_TYPES,
  RATE_TYPES,
} from "lookup";
import { sleep } from "utils/general";
import UtilsLib from "utils/lib";
import RatingReasonsModal from "components/RatingReasonsModal";
import CandidateGridView from "components/CandidatesView/GridView";
import SelectCandidatesViewPanel from "components/CandidatesView/SelectCandidatesViewPanel";
import CandidatesListView from "components/CandidatesView/ListView";
import PageHeader from "components/CandidatesView/PageHeader";
import { getCalibrationVirtualStatus } from "helpers/utils";
import { addMatchFieldHistoryToMatch } from "utils/matchFieldHistory";

export default function Calibrations() {
  const { jobId } = useParams();

  const [modalDisplayed, setModalDisplay] = useState(null);
  const [loadingJobOpp, setLoadingJobOpp] = useState(true);
  const [jobOpp, setJobOpp] = useState({});

  const [candidateSelected, setCandidateSelected] = useState();
  const [cognitoGroups, setCognitoGroups] = useState([]);

  const [mode, setMode] = useState(CANDIDATES_VIEW_MODES.LISTVIEW);

  const gridViewRef = useRef(null);
  const auth = useAuth();

  const loadJobOpportunityMatches = async (nextToken = null) => {
    const response = await API.graphql(
      graphqlOperation(getJobOpportunityMatchesCalibrations, {
        id: jobId,
        nextToken,
      })
    );
    const matches = response.data.getJobOpportunity.matches;

    if (matches.nextToken) {
      await sleep(250);
      const matchesNextPortions = await loadJobOpportunityMatches(
        matches.nextToken
      );

      return [...matches.items, ...matchesNextPortions];
    }

    return matches.items;
  };

  const loadJobOpportunity = async () => {
    const query = getJobOpportunityAndItsMatchCalibrations;
    const response = await API.graphql(graphqlOperation(query, { id: jobId }));
    const matches = response.data.getJobOpportunity.matches;

    if (matches.nextToken) {
      await sleep(250);
      const matchesNextPortions = await loadJobOpportunityMatches(
        matches.nextToken
      );

      matches.items.push(...matchesNextPortions);
    }
    return response;
  };

  useEffect(() => {
    let isMounted = true;
    document.title = `Applications Review - ${jobId}`;

    (async () => {
      const response = await loadJobOpportunity();

      if (isMounted) {
        const tempJobOpp = response.data.getJobOpportunity;
        tempJobOpp.matches.items = await addMatchFieldHistoryToMatch(
          tempJobOpp.matches.items
        );

        for (const match of tempJobOpp.matches.items) {
          const noteResp = await API.graphql(
            graphqlOperation(listMatchNotes, {
              applicationId: match.applicationId,
              jobOpportunityId: { eq: jobId },
            })
          );

          const { compare } = Intl.Collator("en-US");

          const sortedNotes = (noteResp.data.listMatchNotes.items || [])
            .filter(
              ({ noteType, creator }) =>
                noteType === NOTE_TYPES.CALIBRATION &&
                creator !== auth?.user.username
            )
            .sort((a, b) => compare(b.createdAt, a.createdAt));
          match.calibrationNote = sortedNotes[0];
        }

        setJobOpp(tempJobOpp);
        setCandidateSelected(tempJobOpp.matches.items[0]);

        setLoadingJobOpp(false);
      }
    })();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);

  // Fetch groups the logged in user belongs to
  useEffect(() => {
    (async () => {
      try {
        const auth = await Auth.currentSession();

        const groups = auth.getAccessToken().payload["cognito:groups"] || [];

        setCognitoGroups(groups);
      } catch (err) {
        console.log("Error getting current session", err);
      }
    })();
  }, []);

  const isAdmin = useCallback(() => {
    return (
      cognitoGroups.includes(process.env.REACT_APP_COGNITO_ADMIN_GROUP) ||
      cognitoGroups.includes(
        process.env.REACT_APP_COGNITO_GROUP_USER_MANAGERS
      ) ||
      cognitoGroups.includes(process.env.REACT_APP_COGNITO_GROUP_JOB_MANAGERS)
    );
  }, [cognitoGroups]);

  if (loadingJobOpp) {
    return (
      <>
        <Header
          backgroundStyle={{
            background: "#BA89CD",
          }}
          pageHeader={
            <div className="w-full flex items-center justify-center pb-20 md:px-20 px-8">
              <span className="loader"></span>
            </div>
          }
        />
        <Footer />
      </>
    );
  }

  const isLVMode = () => {
    return mode === CANDIDATES_VIEW_MODES.LISTVIEW;
  };

  const onGridScrollLeft = () => {
    gridViewRef.current.scrollBy({
      left: -250,
      behaviour: "smooth",
    });
  };

  const onGridScrollRight = () => {
    gridViewRef.current.scrollBy({
      left: 250,
      behaviour: "smooth",
    });
  };

  const getIndexOfJobOppMatch = (matchCandidate) => {
    return jobOpp.matches.items.findIndex((m) => {
      return (
        m.applicationId === matchCandidate.applicationId &&
        m.jobOpportunityId === matchCandidate.jobOpportunityId
      );
    });
  };

  const updateJobOppMatchState = (matchCandidate) => {
    const index = getIndexOfJobOppMatch(matchCandidate);
    if (index !== -1) {
      jobOpp.matches.items[index] = matchCandidate;
      setJobOpp((prev) => ({
        ...prev,
        matches: { items: jobOpp.matches.items },
      }));
      setCandidateSelected(matchCandidate);
    }
  };

  const onClose = () => {
    setModalDisplay(null);
  };

  const modals = {
    ratingReasons: {
      renderCustomContent: (props) => (
        <RatingReasonsModal onBack={onClose} onClose={onClose} {...props} />
      ),
    },
  };

  const checkIsReadOnly = (match, jobOpp) => {
    return (
      match.status === JOB_APPLICATION_MATCH_STATUS.ACCEPTED ||
      jobOpp.status === JOB_OPPORTUNITY_STATUSES.FULFILLED ||
      !jobOpp.calibrationIsEnabled
    );
  };

  const setRatingReasonsModal = (match, selectedRating) => {
    const modal = modals["ratingReasons"].renderCustomContent({
      application: match,
      jobId: jobOpp?.id,
      applicationId: match?.applicationId,
      updateJobOppMatchState,
      reasonsForRating: match?.reasonsForRating,
      rating: match.rating,
      selectedRating,
    });

    setModalDisplay(modal);
  };

  const handleRatingClick = async (match, selectedRating) => {
    if (
      selectedRating === JOB_APPLICATION_MATCH_RATINGS.VERYUNDESIRABLE ||
      selectedRating === JOB_APPLICATION_MATCH_RATINGS.UNDESIRABLE
    ) {
      setRatingReasonsModal(match, selectedRating);
      return;
    }

    const updatedAttrs = await UtilsLib.Match.updateRating(
      match,
      jobOpp?.id,
      selectedRating,
      match.reasonsForRating?.length > 0
    );

    if (updatedAttrs) {
      updateJobOppMatchState({ ...match, ...updatedAttrs });
    }
  };

  const calibrationsVirtualStatus = getCalibrationVirtualStatus(
    jobOpp.calibrationIsEnabled
  );

  return (
    <>
      <div className="bg-background">
        <Header
          pageHeader={
            <PageHeader
              highLightedBaseTitle="Calibration - "
              title={jobOpp.title}
              infoMessage={
                calibrationsVirtualStatus ===
                CALIBRATION_VIRTUAL_STATUS.NOTYETSTARTED ? (
                  <h3 className="text-red-400 font-[Rubik] text-[16px] font-semibold leading-[1.667] text-center">
                    The Calibration phase has not yet begun
                  </h3>
                ) : calibrationsVirtualStatus ===
                  CALIBRATION_VIRTUAL_STATUS.ACTIVE ? (
                  <p>
                    The goal here is a quick, initial round of feedback. So
                    we're curious for your gut reactions on what's good, what's
                    bad or what's missing. Availability & interest are not yet
                    confirmed (that's the next step after calibration). Rates
                    are based on the last time we spoke with the developer, so
                    while they should be close, the final rate may vary should
                    we match any of the devs below.
                  </p>
                ) : (
                  ""
                )
              }
              gotoActionMessage={
                calibrationsVirtualStatus ===
                  CALIBRATION_VIRTUAL_STATUS.COMPLETE &&
                !!jobOpp.matches.items.length &&
                `The Calibration period has ended. As we match
                candidates to your job you will see them`
              }
              gotoActionPath={`/jobs/applications/${jobOpp.id}`}
            />
          }
        />
        {calibrationsVirtualStatus !==
          CALIBRATION_VIRTUAL_STATUS.NOTYETSTARTED &&
        jobOpp.matches.items.length > 0 ? (
          <>
            <CandidatesListView
              auth={auth}
              candidateSelected={candidateSelected}
              setCandidateSelected={setCandidateSelected}
              setModalDisplay={setModalDisplay}
              updateJobOppMatchState={updateJobOppMatchState}
              handleRatingClick={handleRatingClick}
              checkIsReadOnly={checkIsReadOnly}
              matches={jobOpp.matches.items}
              jobOpp={jobOpp}
              isLVMode={isLVMode()}
              isAdmin={isAdmin()}
              rateType={RATE_TYPES.CALIBRATION}
              noteType={NOTE_TYPES.CALIBRATION}
              modals={modals}
              hideDownloadPDFLink
              hideMatchActions
            >
              <SelectCandidatesViewPanel
                title="Calibration Profiles:"
                mode={mode}
                onModeChange={setMode}
                onScrollLeft={onGridScrollLeft}
                onScrollRight={onGridScrollRight}
              />
            </CandidatesListView>
            {!isLVMode() && (
              <CandidateGridView
                matches={jobOpp.matches.items}
                ref={gridViewRef}
                jobOpp={jobOpp}
                modals={modals}
                updateJobOppMatchState={updateJobOppMatchState}
                handleRatingClick={handleRatingClick}
                setModalDisplay={setModalDisplay}
                isAdmin={isAdmin()}
                checkIsReadOnly={checkIsReadOnly}
                hideMatchActions
                rateType={RATE_TYPES.CALIBRATION}
                noteType={NOTE_TYPES.CALIBRATION}
              />
            )}
          </>
        ) : (
          <div className="flex justify-center items-center mt-[10vh] mb-[22vh]">
            <p className="font-nexa text-red-400 leading-8 text-2xl tracking-wider">
              {calibrationsVirtualStatus !==
                CALIBRATION_VIRTUAL_STATUS.NOTYETSTARTED &&
                "This job has no calibrations"}
            </p>
          </div>
        )}

        <Footer />
      </div>
      {modalDisplayed && (
        <Modal onClose={onClose}>
          <div className="h-full w-full md:h-5/6 lg:max-w-4xl bg-white rounded-none no-scrollbar overflow-hidden relative rounded_modal !max-w-[80vw]">
            {modalDisplayed}
          </div>
        </Modal>
      )}
    </>
  );
}
