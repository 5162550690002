import { useEffect } from "react";

import Availability from "./Availability";
import Rate from "./Rate";
import Profile from "./Profile";
import getCurrentClass from "../shared/utils";
import ProjectStyle from "./ProjectStyle";
import NoticePeriod from "./NoticePeriod";

const ProfessionalProfile = ({
  user,
  handleSubStepQueryParams,
  currentSubStep,
  refs,
  saveAttributes,
  setDisabled,
  setReminderText,
}) => {
  useEffect(() => {
    handleSubStepQueryParams();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pt-9 md:pt-0 compact-text container-large">
      {currentSubStep < 5 ? (
        <>
          <h1 className="h2 mb-[1vh]">Professional profile</h1>
          <div className="flex flex-col gap-28 sm:gap-52">
            <ProjectStyle
              setReminderText={setReminderText}
              clasName={getCurrentClass(1, currentSubStep)}
              jobRef={refs[0]}
              user={user}
              save={saveAttributes}
              setDisabled={setDisabled}
              isCurrent={currentSubStep === 1}
            />
            {currentSubStep > 1 && (
              <Availability
                setReminderText={setReminderText}
                user={user}
                className={getCurrentClass(2, currentSubStep)}
                availabilityRef={refs[1]}
                save={saveAttributes}
                setDisabled={setDisabled}
                isCurrent={currentSubStep === 2}
              />
            )}
            {currentSubStep > 2 && (
              <NoticePeriod
                setReminderText={setReminderText}
                user={user}
                className={getCurrentClass(3, currentSubStep)}
                noticePeriodRef={refs[2]}
                save={saveAttributes}
                setDisabled={setDisabled}
                isCurrent={currentSubStep === 3}
              />
            )}
            {currentSubStep > 3 && (
              <Rate
                setReminderText={setReminderText}
                user={user}
                className={getCurrentClass(4, currentSubStep)}
                rateRef={refs[3]}
                save={saveAttributes}
                setDisabled={setDisabled}
              />
            )}
          </div>
        </>
      ) : (
        <Profile
          user={user}
          profileRef={refs[4]}
          profileCoverPictureRef={refs[5]}
          save={saveAttributes}
        />
      )}
    </div>
  );
};

export default ProfessionalProfile;
