import Referral from "./Referral";
import Match from "./Match";
import User from "./User";

const functionsToExport = {
  Referral,
  Match,
  User,
};

export default functionsToExport;
