export const PROJECT_STYLES = [
  {
    id: "FULLTIME",
    title: "Full-time jobs",
    logo: "guy-headphones-blue.svg",
  },
  {
    id: "PARTTIME",
    title: "Part-time jobs",
    logo: "community-woman-man-fist-bump.svg",
  },
];
