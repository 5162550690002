import { Link } from "react-router-dom";
import classNames from "classnames";

const PageHeader = ({
  highLightedBaseTitle,
  title,
  infoMessage,
  gotoActionMessage,
  backActionPath = "/jobs/opportunities",
  backActionTitle = "Back to My Jobs",
  gotoActionPath = "/jobs/opportunities",
  gotoActionLinkTitle = "Here",
  className,
}) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-center mx-32 mt-8 pb-4",
        className
      )}
    >
      <div className="w-full tracking-wider">
        <div className="pb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="6"
            viewBox="0 0 33 6"
            fill="none"
            className="inline-block"
          >
            <path
              d="M0.75 2.90625L5.75 5.793V0.0194986L0.75 2.90625ZM32.75 2.40625L5.25 2.40625V3.40625L32.75 3.40625V2.40625Z"
              fill="#202021"
            />
          </svg>
          <Link
            to={backActionPath}
            className="w-156.316 h-20.25 flex-shrink-0 text-#202021 font-rubik text-20 font-semibold pl-[16px]"
          >
            {backActionTitle}
          </Link>
        </div>
        <div className="w-full h-auto flex-shrink-0 rounded-[8px] bg-white py-[24px] px-[24px] drop-shadow-lg">
          <div className="flex justify-center items-center display-inline-block">
            {highLightedBaseTitle && (
              <>
                <h1 className="w-auto h-auto flex-shrink-0 text-[#5A19AD] font-rubik text-[32px] font-bold leading-normal display-inline-block pr-2">
                  {highLightedBaseTitle}
                </h1>
              </>
            )}
            <h1 className="w-auto h-auto flex-shrink-0 text-[#202021] text-opacity-80 text-[28px] font-normal leading-normal display-inline-block">
              {title}
            </h1>
          </div>
          <svg
            className="w-full h-auto transform p-4"
            viewBox="0 0 1161 2"
            fill="none"
          >
            <path
              d="M1.25 0.944336H1160.04"
              stroke="#202021"
              strokeLinecap="round"
            />
          </svg>
          {infoMessage && (
            <div className="flex justify-center items-center mx-12 pl-4 pb-4 pr-4 w-75 h-83.09 flex-shrink-0 text-justify text-[#202021] font-[Rubik] text-[12px] font-[300]">
              {infoMessage}
            </div>
          )}
          {gotoActionLinkTitle && gotoActionPath && gotoActionMessage && (
            <div className="flex justify-center items-center">
              <p className="w-609.391 h-21.139 flex-shrink-0 text-[#202021] font-[Rubik] text-[16px] font-semibold leading-[1.667] pr-2">
                <>{gotoActionMessage}&nbsp;</>
              </p>
              <div className="w-12 flex-shrink-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="7"
                  viewBox="0 0 33 7"
                  fill="none"
                >
                  <path
                    d="M32.3438 3.49414L27.3438 0.607389V6.38089L32.3438 3.49414ZM0.34375 3.99414H27.8438V2.99414H0.34375V3.99414Z"
                    fill="#202021"
                  />
                </svg>
              </div>
              <Link
                className="inline-flex items-center justify-center px-4 py-2 text-[#202021] text-[18px] leading-[28px] font-semibold bg-transparent border border-[#202021] rounded-[8px] text-center font-rubik"
                to={gotoActionPath}
              >
                {gotoActionLinkTitle}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
