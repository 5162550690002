import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function MemberName({ name, linkTo }) {
  return (
    <Link alt="Member's Dashboard" className="cursor-pointer" to={linkTo}>
      {name}
    </Link>
  );
}

MemberName.propTypes = {
  name: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
};

MemberName.defaultProps = {
  linkTo: "/dashboard",
};

export default MemberName;
