import Input from "components/FormComponentsNew/Input";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const NumericInput = ({
  defaultValue,
  save,
  id,
  label,
  maxValue,
  minValue = 0,
  ...props
}) => {
  const [number, setNumber] = useState(defaultValue || 0);
  const [maxError, setMaxError] = useState(false);

  useEffect(() => {
    if (defaultValue === number) return;
    save(number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number, defaultValue]);

  return (
    <>
      <Input
        id={id}
        type="number"
        label={label}
        defaultValue={defaultValue}
        placeholder="0"
        value={number || ""}
        onKeyDown={(evt) =>
          ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
        }
        onChange={(e) => {
          let value = Number(e.target.value);
          if (maxValue && value > maxValue) {
            setMaxError(true);
            setNumber(maxValue);
            return;
          } else if (maxError) {
            setMaxError(false);
          }

          setNumber(Math.max(value, minValue));
        }}
        min={0}
        {...props}
      />
      {maxError && (
        <div className="text-functionalDanger text-center mt-1 text-sm">
          Enter a value less than or equal to ${maxValue}
        </div>
      )}
    </>
  );
};

NumericInput.prototypes = {
  defaultValue: PropTypes.number,
  save: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
};
export default NumericInput;
