import xss from "xss";
import Turndown from "turndown";
import MarkdownIt from "markdown-it";
import underline from "markdown-it-plugin-underline";

let turndown;
let mdParser;

export const purifyMarkDown = (input) => {
  if (!turndown) {
    turndown = new Turndown({
      headingStyle: "atx",
      bulletListMarker: "-",
      codeBlockStyle: "indented",
    });

    // rule to comply with markdown-it underline plugin
    turndown.addRule("underline", {
      filter: "u",
      replacement: function (content) {
        return "++" + content + "++";
      },
    });
  }

  const clean = xss(mdItParser(input));
  return xss(turndown.turndown(clean));
};

export const getPurifiedParsedMarkDown = (input) => {
  const cleanMarkDown = purifyMarkDown(input);
  return mdItParser(cleanMarkDown);
};

export const mdItParser = (input, renderInline) => {
  if (!mdParser) {
    mdParser = new MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
      langPrefix: "language-",
    }).use(underline);

    // Open links in new tab
    const defaultRender =
      mdParser.renderer.rules.link_open ||
      ((tokens, idx, options, env, self) => {
        return self.renderToken(tokens, idx, options);
      });

    mdParser.renderer.rules.link_open = (tokens, idx, options, env, self) => {
      // If you are sure other plugins can't add `target` - drop check below
      const aIndex = tokens[idx].attrIndex("target");

      if (aIndex < 0) {
        tokens[idx].attrPush(["target", "_blank"]); // add new attribute
      } else {
        tokens[idx].attrs[aIndex][1] = "_blank"; // replace value of existing attr
      }

      // pass token to default renderer.
      return defaultRender(tokens, idx, options, env, self);
    };
  }

  if (renderInline) {
    return mdParser.renderInline(input);
  }
  return mdParser.render(input);
};
