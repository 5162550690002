import { placementIsActive } from "pages/Profile/Wizard/Experience/utils";

export const sleep = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const mergeCareersAndPlacements = (careers, placements) => {
  const formatPlacements =
    placements
      ?.map((placement) => {
        if (placement.careerEndDate !== null) {
          placement.endDate = placement.careerEndDate || null;
        } else {
          const endDate = placement.actualEndDate ?? placement.endDate;

          if (placementIsActive(endDate)) {
            placement.endDate = null;
          } else {
            placement.endDate = endDate;
          }
        }

        if (placement.careerStartDate) {
          placement.startDate = placement.careerStartDate;
        }

        return placement;
      })
      ?.filter((p) => !p.careerIsHidden) ?? [];

  return [...(careers ?? []), ...formatPlacements];
};

export const convertSalaryToRatePerHour = (salary) => {
  if (typeof salary !== "number") {
    return salary;
  }

  return parseFloat((salary / 2080).toFixed(0));
};
