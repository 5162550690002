import classNames from "classnames";
import CandidateProfile from "./components/CandidateProfile";
import ListCandidates from "./components/ListCandidates";

const CandidatesListView = ({
  auth,
  showMatchSummary,
  candidateSelected,
  setCandidateSelected,
  setModalDisplay,
  updateJobOppMatchState,
  deleteJobOppMatchFromState,
  handleRatingClick,
  jobCalendarEvents,
  checkIsReadOnly,
  matches,
  jobOpp,
  isAdmin,
  isLVMode,
  modals,
  rateType,
  noteType,
  hideDownloadPDFLink,
  hideMatchActions,
  children,
}) => {
  return (
    <div
      className={classNames("flex-none mt-11", {
        "flex drop-shadow-lg gap-6 mb-8 mx-32": isLVMode,
      })}
    >
      <div
        className={classNames("bg-white rounded-sm h-fit py-6", {
          "w-[420px] px-4": isLVMode,
          "w-full pl-32 flex border-b": !isLVMode,
        })}
      >
        {children}
        {isLVMode && (
          <ListCandidates
            matches={matches}
            candidateSelected={candidateSelected}
            setCandidateSelected={setCandidateSelected}
            jobCalendarEvents={jobCalendarEvents}
            rateType={rateType}
          />
        )}
      </div>
      {isLVMode && (
        <CandidateProfile
          match={candidateSelected}
          showMatchSummary={showMatchSummary}
          jobOpp={jobOpp}
          user={candidateSelected.user}
          updateJobOppMatchState={updateJobOppMatchState}
          deleteJobOppMatchFromState={deleteJobOppMatchFromState}
          handleRatingClick={handleRatingClick}
          jobCalendarEvents={jobCalendarEvents}
          setModalDisplay={setModalDisplay}
          candidateRate={candidateSelected[rateType]}
          noteType={noteType}
          modals={modals}
          isReadOnly={checkIsReadOnly(candidateSelected, jobOpp)}
          hideDownloadPDFLink={hideDownloadPDFLink}
          hideMatchActions={hideMatchActions}
          minScore={
            isAdmin || auth.user.id === candidateSelected.user.id ? 0 : 70
          }
        />
      )}
    </div>
  );
};

export default CandidatesListView;
