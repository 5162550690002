export const REFERRAL_SOURCES = {
  cp: "DIRECT",
  em: "EMAIL",
  fb: "FACEBOOK",
  ln: "LINKEDIN",
  pf: "PROFILE",
  rd: "REDDIT",
  tw: "TWITTER",
  wa: "WHATSAPP",
};
