import { useEffect, useMemo, useState } from "react";
import {
  getFormattedExperience,
  getFormattedTimeZone,
  getLocalTime,
  getTimeZoneId,
} from "helpers/utils";
import { MAP_AVAILABILITY } from "constants";
import { getMatchHistoryByStatus } from "utils/matchFieldHistory";
import { JOB_APPLICATION_MATCH_STATUS } from "lookup";
import { Link } from "react-router-dom";
import { mergeCareersAndPlacements } from "utils/general";

export default function CandidateGridSummary(props) {
  const [localTime, setLocalTime] = useState();
  const [timeZoneId, setTimeZoneId] = useState();

  const finalCareers = useMemo(() => {
    return mergeCareersAndPlacements(props.careers, props.placements);
  }, [props.careers, props.placements]);

  const { matchedHistory, appliedHistory } = useMemo(() => {
    const matchedHistory = getMatchHistoryByStatus(
      props.matchFieldHistory,
      JOB_APPLICATION_MATCH_STATUS.MATCHED
    );

    const appliedHistory = getMatchHistoryByStatus(
      props.matchFieldHistory,
      JOB_APPLICATION_MATCH_STATUS.APPLIED
    );

    return { matchedHistory, appliedHistory };
  }, [props.matchFieldHistory]);

  useEffect(() => {
    if (!props.location) return;

    (async () => {
      const timeZoneId = await getTimeZoneId(props.location);
      if (timeZoneId) {
        setTimeZoneId(timeZoneId);
      }
    })();
  }, [props.location]);

  useEffect(() => {
    const timer = setInterval(() => {
      const localTime = getLocalTime(timeZoneId);
      setLocalTime(localTime);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [timeZoneId]);

  return (
    <section className="w-[420px] bg-white border-r h-full border-b p-8">
      <section className="mb-4">
        <h5 className="font-nexa font-bold text-xl mb-4">Summary</h5>
        <dl className="grid grid-cols-2">
          <dt className="text-gray-500">Experience</dt>
          <dd className="text-right">{getFormattedExperience(finalCareers)}</dd>
          <dt className="text-gray-500">
            Local Time <br />({getFormattedTimeZone(timeZoneId)})
          </dt>
          <dd className="text-right">{localTime}</dd>
          <dt className="text-gray-500">Availability</dt>
          <dd className="text-right">
            {MAP_AVAILABILITY[props.availability] ?? "N/a"}
          </dd>
          <dt className="text-gray-500">
            <p>Matched</p>
            <p>
              {matchedHistory?.creator ? (
                <>
                  by{" "}
                  <Link
                    to={`/profile/${matchedHistory?.creator || ""}`}
                    target="_blank"
                  >
                    @${matchedHistory?.creator}
                  </Link>
                </>
              ) : (
                "-"
              )}
            </p>
          </dt>
          <dd className="text-right">
            {matchedHistory?.formattedCreatedAt || "-"}
          </dd>
          <dt className="text-gray-500">Applied</dt>
          <dd className="text-right">
            {appliedHistory?.formattedCreatedAt || "-"}
          </dd>
          {/* <dt className="text-gray-500">Ready to start</dt>
          <dd className="text-right"></dd> */}
        </dl>
      </section>
    </section>
  );
}
