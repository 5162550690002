import React, { useEffect, useState, useCallback } from "react";
import { Auth } from "aws-amplify";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import GetStartedBtn from "components/buttons/GetStarted/GetStarted";
import Articles from "components/Articles";
import SocialShare from "components/SocialShare";

import JobsSection from "./molecules/JobsSection";
import { HeaderSection } from "./molecules/HeaderSection";

import { ARTICLES } from "lookup";
import styles from "./index.module.css";

export const CustomerDashboard = ({ appsyncUser }) => {
  const [cognitoGroups, setCognitoGroups] = useState([]);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const auth = await Auth.currentSession();

        const groups = auth.getAccessToken().payload["cognito:groups"] || [];
        setCognitoGroups(groups);
      } catch (err) {
        console.log("Error getting current session", err);
      }
    })();
  }, [appsyncUser]);

  const canUserAccessJobOpps = useCallback(() => {
    return cognitoGroups.includes(
      process.env.REACT_APP_COGNITO_CUSTOMER_JOB_APPROVED_GROUP
    );
  }, [cognitoGroups]);

  return (
    <>
      <div>
        <HeaderSection appsyncUser={appsyncUser} />
        <a
          href="https://www.torc.dev/newsroom"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="mb-24"
            src={require(`images/banners/torc+randstad_banner.png`)}
            alt="banner"
          />
        </a>
        {canUserAccessJobOpps() ? (
          <JobsSection userId={appsyncUser.id} />
        ) : (
          <section
            className={classNames(
              "relative overflow-hidden flex justify-center items-center flex-col md:flex-row px-6 py-24 pt-32 md:py-32",
              "full-width",
              styles.customerGradient,
              styles["customer-dashboard-body"]
            )}
          >
            <div
              className={classNames(
                "flex flex-col px-0 md:px-8 lg:px-14 tracking-wider gap-y-8 justify-center items-start w-full"
              )}
            >
              <div className="flex flex-col relative w-full p-4">
                <h5 className="font-nexa mb-7">Featured Content</h5>
                <Articles articles={ARTICLES} />
              </div>
            </div>
          </section>
        )}
        <section
          className={classNames(
            "flex flex-col items-center justify-center py-16"
          )}
        >
          <div className="mb-4">
            <h2 className="text-3xl mb-6 font-nexa tracking-wider text-electricBlue-700">
              Ready to post a job?
            </h2>
            {!canUserAccessJobOpps() && (
              <p className="font-rubik-regular text-xl tracking-wider">
                You'll need an active agreement in place to post a job. Not to
                worry, it's a quick and simple process.{" "}
                <a
                  className="text-blue-700"
                  href={`https://www.torc.dev/contact?firstname=${appsyncUser.given_name}&lastname=${appsyncUser.family_name}&email=${appsyncUser.email}&reason=hire`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Send us a note
                </a>{" "}
                and we'll be in touch to get you set up.
              </p>
            )}
            {canUserAccessJobOpps() && (
              <>
                <p className="font-rubik-regular text-xl tracking-wider">
                  Great, you'll be working with your next developer in no time.
                  You can post a job using the button below or by opening the
                  menu on the right side of your screen.
                </p>
                <div className="mt-6">
                  <GetStartedBtn
                    onClick={() => history.push("/jobs/opportunities/create")}
                    label={"POST A NEW JOB"}
                    smallButton
                    variantBlack
                  />
                </div>
              </>
            )}
          </div>
        </section>
        <section className={classNames("flex flex-col justify-center pb-16")}>
          <div className="mb-8">
            <p className="text-3xl mb-6 tracking-wider text-electricBlue-700">
              Have you heard about our{" "}
              <a
                className="font-rubik-regular text-3xl mb-6 font-bold tracking-wider text-electricBlue-700 hover:underline"
                href="https://www.torc.dev/talent/referrals"
                target="_blank"
                rel="noreferrer"
                alt="link to our referral program page"
              >
                referral program
              </a>
              ?
            </p>
            <p className="font-rubik-regular text-xl tracking-wider ">
              The great thing about a marketplace is it keeps getting stronger
              and stronger when everyone is successful—more jobs leads to more
              developers, and more developers means you're able to find a great
              match, faster. Read all about our{" "}
              <a
                className="font-rubik-regular text-electricBlue-700 font-bold hover:underline"
                href="https://www.torc.dev/talent/referrals"
                target="_blank"
                rel="noreferrer"
                alt="link to our referral program page"
              >
                referral program
              </a>
              !
            </p>
            <p className="font-rubik-regular text-xl tracking-wider mt-6">
              We're ready to put our money where our mouth is. If you refer a
              developer or a customer to Torc, we'll give you $500 and your
              referral $250 dollars once they are staffed or sign up as a
              customer!
            </p>
            <p className="font-rubik-regular text-xl tracking-wider mt-6">
              To be eligible for this revenue share, the new developer or
              customer must sign up using your personalized referral code.
            </p>
          </div>
          {appsyncUser && (
            <SocialShare
              referralCode={appsyncUser.referralCode}
              referralCount={appsyncUser.referralCount}
              userType={appsyncUser.userType}
            />
          )}
        </section>
      </div>
    </>
  );
};
