export const FIELDS = {
  TAGLINE: "tagline",
  BIO: "bio",
  SOCIALLINKS: "socialLinks",
  AVAILABILITY: "availability",
  RATE_PER_HOUR: "ratePerHour",
  PHONE_NUMBER: "phoneNumber",
  WHATSAPP_ALLOWED: "whatsAppAllowed",
  LOCATION: "locationSearch",
};
