import classNames from "classnames";
import GetStartedBtn from "components/buttons/GetStarted/GetStarted";
import RenderAppStatus from "components/RenderAppStatus";
import { Link } from "react-router-dom";
import { getJobDuration } from "helpers/utils";

const JobMatchCard = ({
  job,
  buttonLabel = "View Job",
  path = "/jobs/matches",
  linkState,
  matchStatus,
  jobReferral,
}) => {
  const skillNames = job?.skills?.map((skill) => skill.name).join(", ");

  const optionalSkillNames = job?.optionalSkills
    ?.map((skill) => skill.name)
    .join(", ");

  return (
    <div
      className={classNames(
        "bg-gradient-to-r from-[#83D9BB] to-[#F4D675] p-0.5",
        "shadow-xs hover:shadow-sm transition-shadow my-4 max-w-5xl w-full bg-white"
      )}
    >
      <div className="bg-white px-3 py-2">
        <div className="flex flex-row justify-between mb-2">
          <RenderAppStatus status={matchStatus} type="freelancer" />
          {jobReferral && (
            <div>
              <p className="text-sm font-rubik-regular">
                Referred by:{" "}
                <span className="font-semibold">
                  {jobReferral.jobReferrerUsername}
                </span>
              </p>
            </div>
          )}
        </div>

        <div
          className={classNames("flex flex-col justify-between md:flex-row")}
        >
          <div className="w-full">
            <div className="font-nexa font-bold tracking-wider !text-xl lg:!text-base">
              {job?.title}
            </div>
            <div className="my-2 block md:hidden pr-16 md:pr-4">
              <p className="font-rubik-regular text-sm italic font-light ">
                required:{" "}
                <span className="text-electricBlue">{skillNames}</span>
              </p>
              {optionalSkillNames && (
                <p className="font-rubik-regular text-sm italic font-light">
                  others:{" "}
                  <span className="text-electricBlue">
                    {optionalSkillNames}
                  </span>
                </p>
              )}
            </div>
          </div>

          <div className="font-rubik-regular text-electricBlue-800 font-medium italic text-sm mt-7 md:ml-2 md:mr-2 md:mt-0 md:pr-4">
            <div>{job?.timeCommitment}</div>
            <div className="whitespace-nowrap">
              {getJobDuration(job?.jobLength, job?.jobLengthInWeeks)}
            </div>
          </div>
          <div>
            <Link
              to={{
                pathname: `${path}/${job?.id}`,
                state: linkState,
              }}
            >
              <GetStartedBtn
                label={buttonLabel}
                className="text-black flex-nowrap mt-6 w-44 md:mt-0"
                uppercase
                hideArrow
                smallButton
                alt={buttonLabel}
              />
            </Link>
          </div>
        </div>
        <h4 className="font-rubik-regular text-sm italic  font-light hidden md:block">
          {optionalSkillNames && "primary: "}
          <span className="text-electricBlue">{skillNames}</span>
        </h4>

        {optionalSkillNames && (
          <h4 className="font-rubik-regular text-sm italic  font-light hidden md:block">
            others:{" "}
            <span className="text-electricBlue">{optionalSkillNames}</span>
          </h4>
        )}
      </div>
    </div>
  );
};

export default JobMatchCard;
