import DESIRABLESRC from "images/reactions/DESIRABLE.png";
import NEUTRALSRC from "images/reactions/NEUTRAL.png";
import UNDESIRABLESRC from "images/reactions/UNDESIRABLE.png";
import VERYDESIRABLESRC from "images/reactions/VERYDESIRABLE.png";
import VERYUNDESIRABLESRC from "images/reactions/VERYUNDESIRABLE.png";
import CandidateCard from "./CandidateCard";

const RATINGS_SRC = {
  DESIRABLE: DESIRABLESRC,
  NEUTRAL: NEUTRALSRC,
  UNDESIRABLE: UNDESIRABLESRC,
  VERYDESIRABLE: VERYDESIRABLESRC,
  VERYUNDESIRABLE: VERYUNDESIRABLESRC,
};

const ListCandidates = ({
  matches,
  candidateSelected,
  setCandidateSelected,
  jobCalendarEvents,
  rateType,
}) => {
  return (
    <div>
      {matches?.map((match, idx) => {
        const candidate = match.user;

        return (
          candidate && (
            <div
              className={`p-4 relative cursor-pointer ${
                candidateSelected === match && "bg-orange-100"
              }`}
              onClick={() => setCandidateSelected(match)}
              key={`candidate-${idx}`}
            >
              {match.rating && (
                <div className="absolute top-0 left-0 z-50">
                  <img
                    src={RATINGS_SRC[match.rating]}
                    alt="rating"
                    width="auto"
                    height="auto"
                  />
                </div>
              )}
              <CandidateCard
                candidate={candidate}
                jobCalendarEvents={jobCalendarEvents}
                size="small"
                rate={match[rateType]}
              />
            </div>
          )
        );
      })}
    </div>
  );
};

export default ListCandidates;
