import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { useAuth } from "GlobalAuthContext";
import Modal from "components/Modal";
import { isEqual, cloneDeep } from "lodash";
import {
  getJobOpportunityAndItsMatchFinalists,
  getJobOpportunityEvents,
  getJobOpportunityMatchesFinalists,
} from "graphql/queries";

import Footer from "components/Footer";
import Header from "components/Header";
import {
  CANDIDATES_VIEW_MODES,
  JOB_APPLICATION_MATCH_STATUS,
  JOB_OPPORTUNITY_STATUSES,
  RATE_TYPES,
} from "lookup";

import { addMatchFieldHistoryToMatch } from "utils/matchFieldHistory";
import { sleep } from "utils/general";
import UtilsLib from "utils/lib";
import DenyApplicant from "../ApplicantReview/ApplicantModal/denyModal";
import AcceptModal from "../ApplicantReview/ApplicantModal/AcceptModal";
import SchedulerModal from "../ApplicantReview/ApplicantModal/SchedulerModal";
import RatingReasonsModal from "components/RatingReasonsModal";
import CandidateGridView from "components/CandidatesView/GridView";
import SelectCandidatesViewPanel from "components/CandidatesView/SelectCandidatesViewPanel";
import CandidatesListView from "components/CandidatesView/ListView";
import PageHeader from "components/CandidatesView/PageHeader";

export default function Finalists() {
  const { jobId } = useParams();

  const [modalDisplayed, setModalDisplay] = useState(null);
  const [loadingJobOpp, setLoadingJobOpp] = useState(true);
  const [jobOpp, setJobOpp] = useState({});
  const [jobOppEvents, setJobOppEvents] = useState(null);

  const [candidateSelected, setCandidateSelected] = useState();
  const [cognitoGroups, setCognitoGroups] = useState([]);

  const [mode, setMode] = useState(CANDIDATES_VIEW_MODES.LISTVIEW);
  const [interValObj, setIntervalObj] = useState({ timer: null });

  const gridViewRef = useRef(null);
  const auth = useAuth();

  const loadJobOpportunityMatches = async (nextToken = null) => {
    const response = await API.graphql(
      graphqlOperation(getJobOpportunityMatchesFinalists, {
        id: jobId,
        nextToken,
      })
    );
    const matches = response.data.getJobOpportunity.matches;

    if (matches.nextToken) {
      await sleep(250);
      const matchesNextPortions = await loadJobOpportunityMatches(
        matches.nextToken
      );

      return [...matches.items, ...matchesNextPortions];
    }

    return matches.items;
  };

  const loadJobOpportunity = async () => {
    const query = getJobOpportunityAndItsMatchFinalists;
    const response = await API.graphql(graphqlOperation(query, { id: jobId }));
    const matches = response.data.getJobOpportunity.matches;

    if (matches.nextToken) {
      await sleep(250);
      const matchesNextPortions = await loadJobOpportunityMatches(
        matches.nextToken
      );

      matches.items.push(...matchesNextPortions);
    }
    return response;
  };

  const fetchJobCalendarEvents = async () => {
    const { data } = await API.graphql(
      graphqlOperation(getJobOpportunityEvents, { id: jobId })
    );

    return data.getJobOpportunityEvents;
  };

  const reFreshCalendarEvents = async () => {
    const events = await fetchJobCalendarEvents();
    setJobOppEvents(events);
    return events;
  };

  useEffect(() => {
    let isMounted = true;
    document.title = `Applications Review - ${jobId}`;

    (async () => {
      const response = await loadJobOpportunity();

      const events = await fetchJobCalendarEvents();

      if (isMounted) {
        setJobOppEvents(events);

        const tempJobOpp = response.data.getJobOpportunity;
        tempJobOpp.matches.items = await addMatchFieldHistoryToMatch(
          tempJobOpp.matches.items
        );

        setJobOpp(tempJobOpp);
        setCandidateSelected(tempJobOpp.matches.items[0]);

        setLoadingJobOpp(false);
      }
    })();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);

  // Fetch groups the logged in user belongs to
  useEffect(() => {
    (async () => {
      try {
        const auth = await Auth.currentSession();

        const groups = auth.getAccessToken().payload["cognito:groups"] || [];

        setCognitoGroups(groups);
      } catch (err) {
        console.log("Error getting current session", err);
      }
    })();
  }, []);

  const handleTimeoutInterval = async () => {
    const pastEvents = cloneDeep(jobOppEvents);
    const events = await reFreshCalendarEvents();
    if (!isEqual(pastEvents, events)) {
      setIntervalObj((prev) => ({ ...prev, period: 0 }));
    }
  };

  useEffect(() => {
    let timer;
    let newPeriod;
    let counter = 0;
    // up to ~1.5 min of tries
    if (interValObj.period !== 0 && interValObj.counter < 10) {
      if (interValObj.counter >= 5) {
        counter = interValObj.counter + 1;
        newPeriod = 10000;
      } else {
        counter = interValObj.counter + 1;
        newPeriod = 5000;
      }
      timer = setInterval(() => {
        (async () => {
          await handleTimeoutInterval();
          setIntervalObj((prev) => ({ ...prev, period: newPeriod, counter }));
        })();
      }, newPeriod);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interValObj]);

  const isAdmin = useCallback(() => {
    return (
      cognitoGroups.includes(process.env.REACT_APP_COGNITO_ADMIN_GROUP) ||
      cognitoGroups.includes(
        process.env.REACT_APP_COGNITO_GROUP_USER_MANAGERS
      ) ||
      cognitoGroups.includes(process.env.REACT_APP_COGNITO_GROUP_JOB_MANAGERS)
    );
  }, [cognitoGroups]);

  if (loadingJobOpp) {
    return (
      <>
        <Header
          backgroundStyle={{
            background: "#BA89CD",
          }}
          pageHeader={
            <div className="w-full flex items-center justify-center pb-20 md:px-20 px-8">
              <span className="loader"></span>
            </div>
          }
        />
        <Footer />
      </>
    );
  }

  const isLVMode = () => {
    return mode === CANDIDATES_VIEW_MODES.LISTVIEW;
  };

  const onGridScrollLeft = () => {
    gridViewRef.current.scrollBy({
      left: -250,
      behaviour: "smooth",
    });
  };

  const onGridScrollRight = () => {
    gridViewRef.current.scrollBy({
      left: 250,
      behaviour: "smooth",
    });
  };

  const getIndexOfJobOppMatch = (matchCandidate) => {
    return jobOpp.matches.items.findIndex((m) => {
      return (
        m.applicationId === matchCandidate.applicationId &&
        m.jobOpportunityId === matchCandidate.jobOpportunityId
      );
    });
  };

  const updateJobOppMatchState = (matchCandidate) => {
    const index = getIndexOfJobOppMatch(matchCandidate);
    if (index !== -1) {
      jobOpp.matches.items[index] = matchCandidate;
      setJobOpp((prev) => ({
        ...prev,
        matches: { items: jobOpp.matches.items },
      }));
      setCandidateSelected(matchCandidate);
    }
  };

  const deleteJobOppMatchFromState = (matchCandidate) => {
    const index = getIndexOfJobOppMatch(matchCandidate);
    if (index !== -1) {
      jobOpp.matches.items.splice(index, 1);
      setJobOpp((prev) => ({
        ...prev,
        matches: { items: jobOpp.matches.items },
      }));
      setCandidateSelected(
        jobOpp.matches.items[jobOpp.matches.items.length - 1]
      );
    }
  };

  const onClose = (activeModalType) => {
    if (
      activeModalType === "SchedulerModal" ||
      modalDisplayed?.type?.name === "SchedulerModal"
    ) {
      setIntervalObj((prev) => ({
        ...prev,
        period: 5000,
        counter: 0,
      }));
    }
    setModalDisplay(null);
  };

  const modals = {
    deny: {
      renderCustomContent: (props) => (
        <DenyApplicant onBack={onClose} onClose={onClose} {...props} />
      ),
    },
    accept: {
      renderCustomContent: (props) => (
        <AcceptModal onBack={onClose} onClose={onClose} {...props} />
      ),
    },
    scheduler: {
      renderCustomContent: (props) => (
        <SchedulerModal onBack={onClose} onClose={onClose} {...props} />
      ),
    },
    ratingReasons: {
      renderCustomContent: (props) => (
        <RatingReasonsModal onBack={onClose} onClose={onClose} {...props} />
      ),
    },
  };
  const checkIsReadOnly = (candidateSelected, jobOpp) => {
    return (
      candidateSelected.status === JOB_APPLICATION_MATCH_STATUS.ACCEPTED ||
      jobOpp.status === JOB_OPPORTUNITY_STATUSES.FULFILLED
    );
  };

  const handleRatingClick = async (match, selectedRating) => {
    const updatedAttrs = await UtilsLib.Match.updateRating(
      match,
      jobOpp?.id,
      selectedRating
    );

    if (updatedAttrs) {
      updateJobOppMatchState({ ...match, ...updatedAttrs });
    }
  };

  return (
    <>
      <div className="bg-background">
        <Header pageHeader={<PageHeader title={jobOpp.title} />} />
        {jobOpp.matches.items.length > 0 && (
          <>
            <CandidatesListView
              auth={auth}
              candidateSelected={candidateSelected}
              setCandidateSelected={setCandidateSelected}
              setModalDisplay={setModalDisplay}
              updateJobOppMatchState={updateJobOppMatchState}
              deleteJobOppMatchFromState={deleteJobOppMatchFromState}
              handleRatingClick={handleRatingClick}
              checkIsReadOnly={checkIsReadOnly}
              matches={jobOpp.matches.items}
              jobCalendarEvents={jobOppEvents}
              jobOpp={jobOpp}
              isLVMode={isLVMode()}
              isAdmin={isAdmin()}
              rateType={RATE_TYPES.CUSTOMER}
              modals={modals}
              showMatchSummary
            >
              <SelectCandidatesViewPanel
                title="Candidates:"
                jobCalendarEvents={jobOppEvents}
                mode={mode}
                reFreshCalendarEvents={reFreshCalendarEvents}
                onModeChange={setMode}
                onScrollLeft={onGridScrollLeft}
                onScrollRight={onGridScrollRight}
              />
            </CandidatesListView>
            {!isLVMode() && (
              <CandidateGridView
                matches={jobOpp.matches.items}
                jobCalendarEvents={jobOppEvents}
                ref={gridViewRef}
                jobOpp={jobOpp}
                updateJobOppMatchState={updateJobOppMatchState}
                deleteJobOppMatchFromState={deleteJobOppMatchFromState}
                isAdmin={isAdmin()}
                setModalDisplay={setModalDisplay}
                handleRatingClick={handleRatingClick}
                checkIsReadOnly={checkIsReadOnly}
                modals={modals}
                rateType={RATE_TYPES.CUSTOMER}
                showMatchSummary
              />
            )}
          </>
        )}
        {jobOpp.matches.items.length === 0 && (
          <div className="flex justify-center items-center mt-[10vh] mb-[22vh]">
            <p className="font-nexa text-red-400 leading-8 text-2xl tracking-wider">
              This job has no finalists
            </p>
          </div>
        )}

        <Footer />
      </div>
      {modalDisplayed && (
        <Modal onClose={onClose}>
          <div className="h-full w-full md:h-5/6 lg:max-w-4xl bg-white rounded-none no-scrollbar overflow-hidden relative rounded_modal">
            {modalDisplayed}
          </div>
        </Modal>
      )}
    </>
  );
}
