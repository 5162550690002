import classNames from "classnames";

import Article from "./Article";

function parseArticles(articles) {
  let featuredArticleIdx = 0;

  for (let i = 0; i < articles.length; i++) {
    const article = articles[i];
    const tagList = article.tag_list;
    if (tagList.includes("Featured")) {
      featuredArticleIdx = i;
    }
  }

  const notFeatured = articles.filter((_art, i) => i !== featuredArticleIdx);

  return [articles[featuredArticleIdx], notFeatured];
}

function Articles({ articles }) {
  if (!articles) {
    return null;
  }

  const [featured, notFeatured] = parseArticles(articles);

  return (
    <div
      className={classNames(
        // styles.articleFlex,
        "flex md:flex-row md:justify-between w-full flex-col relative gap-24"
      )}
    >
      <Article featured article={featured} key={"featured-article-1"} />
      <div className="flex flex-col w-full gap-2.5">
        {notFeatured.map((article, i) => (
          <Article article={article} key={`article-${i}`} />
        ))}
      </div>
    </div>
  );
}

export default Articles;
