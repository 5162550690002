/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($id: ID) {
    onUpdateUser(id: $id) {
      id
      codealike {
        handle
        totalPoints
        usedPoints
      }
    }
  }
`;
