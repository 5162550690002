import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const shouldRunAnalytics = !!process.env.REACT_APP_RUN_ANALYTICS;

function PageViewTracker() {
  const location = useLocation();
  const { pathname, search, hash } = location;
  const path = pathname + (hash?.replace(/^#\/?/, "") || "");

  useEffect(() => {
    if (!window.analytics?.page || !shouldRunAnalytics) {
      return;
    }

    window.analytics.page(document.title, {
      path: path + search,
      url: window.location.href,
    });
  }, [path, search]);

  return null;
}

export default PageViewTracker;
