import classNames from "classnames";
import { GEO_REGION_TYPE } from "lookup";
import React, { useState } from "react";
import Select, { components } from "react-select";

// When all countries in a region are selected, we prefix the label with this
const ALL_COUNTRIES_PREFIX = "All - ";

const locations = (() => {
  const originalLocations = JSON.parse(
    process.env.REACT_APP_GEOGRAPHICAL_REGIONS
  );
  const bannedCountries = JSON.parse(process.env.REACT_APP_BANNED_COUNTRIES);

  return originalLocations.map((region) => {
    const filteredCountries = region.countryNames.filter(
      (country) => !bannedCountries.includes(country)
    );

    return {
      ...region,
      countryNames: filteredCountries,
    };
  });
})();

function isRegionSelected(region, value) {
  let newValues = JSON.parse(JSON.stringify(value));

  let regionCountries = locations.find(
    (item) => item.regionName === region
  )?.countryNames;

  if (!regionCountries) {
    return;
  }

  let regionValuesSet = new Set(
    regionCountries.map((country) => getCountryValue(region, country))
  );
  let currentValuesSet = new Set(newValues.map((item) => item.value));

  return [
    Array.from(regionValuesSet).every((v) => currentValuesSet.has(v)),
    regionValuesSet,
    currentValuesSet,
  ];
}

function getCountryValue(regionName, countryName) {
  return `${regionName}__${countryName}`;
}

function getRegionValue(regionName) {
  return `${ALL_COUNTRIES_PREFIX}${regionName}`;
}

function getOptions() {
  return locations.map((l) => ({
    label: getRegionValue(l.regionName),
    options: l.countryNames.map((c) => ({
      label: c,
      value: getCountryValue(l.regionName, c),
    })),
  }));
}

/**
 * Replaces individual country names with their corresponding region name in an array,
 * if all countries from that region are present in the input array.
 * @param {Array} B The selected countries
 */
function replaceAllCountriesWithRegion(B) {
  // Create a mapping from regionName to countryNames
  const regionToCountries = {};
  locations.forEach((item) => {
    regionToCountries[item.regionName] = new Set(
      item.countryNames.map((j) => getCountryValue(item.regionName, j))
    );
  });

  // Create a set for all countries in B for efficient lookup
  const countriesInB = new Set(B.map((item) => item.value));

  // Create a set to keep track of which countries have been replaced
  const replacedCountries = new Set();

  const C = [];

  // Iterate through the regions to see if we can replace countries with regionName
  Object.entries(regionToCountries).forEach(([region, countries]) => {
    if (Array.from(countries).every((country) => countriesInB.has(country))) {
      C.push({ label: getRegionValue(region), value: region });

      // Remember which countries we've replaced
      countries.forEach((country) => replacedCountries.add(country));
    }
  });

  // Now, add any countries from B that weren't replaced by a regionName
  B.forEach((item) => {
    if (!replacedCountries.has(item.value)) {
      C.push(item);
    }
  });

  return C;
}

/**
 * Removes valueToRemove from selectedOptions.
 * If valueToRemove is a region, all corresponding countries are removed
 * Else just that country is removed
 * @param {Array} selectedOptions Array of currently selected options
 * @param {String} valueToRemove Label of option to remove
 */
function removeSelectedValue(selectedOptions, valueToRemove) {
  const isRegion = valueToRemove.startsWith(ALL_COUNTRIES_PREFIX);

  if (isRegion) {
    const regionName = valueToRemove.substring(ALL_COUNTRIES_PREFIX.length);

    const region = locations.find((region) => region.regionName === regionName);

    if (!region) {
      // Region not found, return the list as is
      return selectedOptions;
    }

    const countriesInRegion = new Set(
      region.countryNames.map((country) => getCountryValue(regionName, country))
    );

    return selectedOptions.filter(
      (option) => !countriesInRegion.has(option.value)
    );
  } else {
    return selectedOptions.filter((option) => option.label !== valueToRemove);
  }
}

/**
 * Returns a union of two arrays.
 * Unions will not contain the same element, in this case, we
 * use the items's `value` property to determine uniqueness
 * @param {Array} array1 An array of objects of type {label, value}
 * @param {Array} array2 Another array of same type as array1
 */
function unionArrays(array1, array2) {
  const combinedArray = array1.concat(array2);

  const uniqueArray = combinedArray.filter(
    (obj, index, self) => index === self.findIndex((t) => t.value === obj.value)
  );

  return uniqueArray;
}

const LimitedChips = ({ index, getValue, ...props }) => {
  const values = getValue();

  const optimizedCountryList = replaceAllCountriesWithRegion(values);

  const countries = optimizedCountryList.map((v) => v.label);
  const sortedSelection = [...countries].sort();

  const removeValue = (value) => {
    const latestValues = getValue();

    const valuesAfterRemoval = removeSelectedValue(latestValues, value);

    props.setValue(valuesAfterRemoval, "deselect-option");
  };

  if (index === 0) {
    return (
      <div className="flex flex-wrap gap-2">
        {sortedSelection.map((s) => {
          return (
            <React.Fragment key={s}>
              <span
                className="p-2 flex items-center justify-center font-medium rounded-sm text-sm last:mr-2"
                style={{ backgroundColor: "#0495B726" }}
              >
                {s}
                <button
                  type="button"
                  className="cursor-pointer ml-2 opacity-20 text-xl leading-3"
                  onClick={() => removeValue(s)}
                  style={{ color: "#004B5C" }}
                >
                  &times;
                </button>
              </span>
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  return null;
};

const Country = (props) => {
  return (
    <components.Option {...props}>
      <div className="ml-5 text-sm text-black font-normal capitalize w-full">
        {props.label}
      </div>
    </components.Option>
  );
};

const Region = (props) => {
  const { getStyles, selectProps, data, onSelect } = props;
  const label = data.label;

  const selectAllCountries = () => {
    const newValue = unionArrays(selectProps.value, data.options);

    selectProps.onChange(newValue);

    onSelect();
  };

  return (
    <div style={getStyles("group", props)} className="hover:bg-blue-100">
      <components.GroupHeading {...props}>
        <div
          className="text-sm text-black font-normal capitalize w-full"
          onClick={selectAllCountries}
        >
          {label}
        </div>
      </components.GroupHeading>
    </div>
  );
};

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="11" cy="11" r="8" />
      <path d="m21 21-4.3-4.3" />
    </svg>
  </components.DropdownIndicator>
);

const GeographicalRegionSearch = ({
  onChange,
  value,
  isReadOnly = false,
  geoRegiontype,
}) => {
  const [searchText, setSearchText] = useState("");
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleInputChange = (searchValue, actionMeta) => {
    setSearchText(searchValue);

    if (actionMeta.action === "input-change") {
      setMenuIsOpen(!!searchValue && searchValue?.length > 2);
    }

    if (
      actionMeta.action === "set-value" ||
      actionMeta.action === "remove-value"
    ) {
      setSearchText("");
      setMenuIsOpen(false);
    }

    if (actionMeta.action === "input-blur") {
      setMenuIsOpen(false);
    }
  };

  const regions = locations
    .map((l) => l.regionName)
    .sort((a, b) => {
      if (a === "LATAM") {
        return -1;
      }

      if (b === "LATAM") {
        return 1;
      }

      return 0;
    });

  const toggleRegionSelection = (region) => {
    const newValues = JSON.parse(JSON.stringify(value));

    const [regionIsSelected, regionValuesSet, currentValuesSet] =
      isRegionSelected(region, value);

    if (regionIsSelected !== true && regionIsSelected !== false) {
      return;
    }

    if (regionIsSelected) {
      onChange(newValues.filter((item) => !regionValuesSet.has(item.value)));
    } else {
      regionValuesSet.forEach((countryValue) => {
        if (!currentValuesSet.has(countryValue)) {
          let countryName = countryValue.split("__")[1];

          newValues.push({ label: countryName, value: countryValue });
        }
      });
      onChange(newValues);
    }
  };

  if (geoRegiontype === GEO_REGION_TYPE.BY_COUNTRY) {
    return (
      <div
        className="-m-0.5 p-0.5 rounded flex"
        style={{
          background: `linear-gradient(to right, #83D9BB, #F4D675)`,
        }}
      >
        <div className="bg-white rounded w-full relative">
          <Select
            options={getOptions()}
            onChange={onChange}
            value={value}
            components={{
              DropdownIndicator,
              GroupHeading: (props) => (
                <Region
                  {...props}
                  onSelect={() =>
                    handleInputChange("", { action: "set-value" })
                  }
                />
              ),
              MultiValue: (props) => (
                <LimitedChips {...props} isReadOnly={isReadOnly} />
              ),
              Option: Country,
            }}
            placeholder="Search for countries..."
            styles={{
              control: (base) => ({
                ...base,
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
                boxShadow: "none",
                padding: "8px 16px",
              }),
              indicatorSeparator: () => {},
              option: (base) => ({
                ...base,
                paddingLeft: "1rem",
              }),
              valueContainer: (base) => ({
                ...base,
                paddingLeft: "1rem",
                width: "100%",
              }),
            }}
            closeMenuOnSelect={false}
            hideSelectedOptions={true}
            isClearable={false}
            isDisabled={isReadOnly}
            inputValue={searchText}
            onInputChange={handleInputChange}
            menuIsOpen={menuIsOpen}
            backspaceRemovesValue={false}
            required
            isMulti
            isSearchable
            blurInputOnSelect
          />
        </div>
      </div>
    );
  }

  if (geoRegiontype === GEO_REGION_TYPE.BY_REGION) {
    return (
      <ul className="flex gap-2 mt-5">
        {regions.map((r) => {
          return (
            <li
              key={r}
              className={classNames(
                "py-1 px-2 font flex gap-1 items-center text-sm hover:cursor-pointer hover:bg-blue-500 hover:text-white group font-nexa-regular rounded-xs",
                {
                  "bg-blue-500 text-white": !!isRegionSelected(r, value)[0],
                  "bg-gray-100 text-gray-500": !isRegionSelected(r, value)[0],
                }
              )}
              onClick={() => toggleRegionSelection(r)}
            >
              {r}
            </li>
          );
        })}
      </ul>
    );
  }

  return null;
};

export default GeographicalRegionSearch;
