import React from "react";
import PropTypes from "prop-types";
import Toggle from "react-toggle";

import "./index.css";

const CustomToggle = ({
  checked,
  valueKey,
  leftLabel,
  rightLabel,
  onChange,
}) => {
  const handleChange = ({ target: { checked } }) => {
    onChange(checked, valueKey);
  };

  return (
    <div className="flex items-center">
      {leftLabel && <p className="mr-2">{leftLabel}</p>}

      <Toggle
        checked={checked}
        onChange={handleChange}
        icons={false}
        className="customToggle"
      />

      {rightLabel && <p className="ml-2">{rightLabel}</p>}
    </div>
  );
};

CustomToggle.propTypes = {
  checked: PropTypes.bool,
  valueKey: PropTypes.string,
  helpText: PropTypes.string,
  onChange: PropTypes.func,
};

CustomToggle.defaultProps = {
  checked: true,
  valueKey: "",
  helpText: "",
  onChange: () => {},
};

export default CustomToggle;
