import GithubConnect from "./GithubConnect";
import CodealikeConnect from "./CodealikeConnect";

const ConnectAccounts = ({ user, dispatchUserUpdated }) => {
  return (
    <div className="flex flex-col flex-wrap lg:flex-row w-full pl-0 sm:pl-8 lg:pl-0 gap-y-8">
      <div className="px-0 lg:px-14 w-full lg:w-1/2">
        <GithubConnect
          user={user}
          className="h-full"
          dispatchUserUpdated={dispatchUserUpdated}
        />
      </div>
      <div className="px-0 lg:px-14 w-full lg:w-1/2">
        <CodealikeConnect
          user={user}
          customCard
          className="h-full"
          dispatchUserUpdated={dispatchUserUpdated}
        />
      </div>
    </div>
  );
};

export default ConnectAccounts;
